import React from "react";
import styled, { css } from "styled-components";
import { ThemeColorsType } from "@providers/ThemeProvider/theme";
import { getIcon, IconsKeyType } from "./icons";

interface IProps {
  name: IconsKeyType;
  iconColor?: ThemeColorsType;
  iconSize?: number;
  className?: string;
}

export default function Icon(props: IProps) {
  const CurrentIcon = getIcon(props.name);

  // @ts-ignore
  return (
    <StyledView
      $iconColor={props.iconColor}
      $iconSize={props.iconSize}
      className={props.className}
    >
      <>{CurrentIcon}</>
    </StyledView>
  );
}

const StyledView = styled.div<{
  $iconColor?: IProps["iconColor"];
  $iconSize?: IProps["iconSize"];
}>`
  ${({ theme, $iconColor, $iconSize }) => css`
    ${$iconSize &&
    css`
      width: ${$iconSize}px;
      height: ${$iconSize}px;
    `}

    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      width: 100%;
      height: 100%;
      path {
        ${$iconColor &&
        css`
          fill: ${theme.colors[$iconColor]};
        `}
      }
    }
  `}
`;
