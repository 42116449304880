export const ArrowsIcons = {
  "Swap-1": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2055 4.11252C15.6097 3.70685 16.0582 3.37055 16.6314 3.27923C16.7535 3.25978 16.8766 3.25 17 3.25C17.1234 3.25 17.2466 3.25978 17.3687 3.27923C17.9419 3.37055 18.3904 3.70685 18.7945 4.11252C19.1883 4.50783 19.6283 5.06274 20.1614 5.73522L21.5877 7.53403C21.8451 7.8586 21.7906 8.33034 21.466 8.58768C21.1414 8.84503 20.6697 8.79054 20.4123 8.46597L19.0145 6.703C18.4563 5.99898 18.0727 5.51697 17.75 5.18952L17.75 18C17.75 18.4142 17.4142 18.75 17 18.75C16.5858 18.75 16.25 18.4142 16.25 18L16.25 5.18952C15.9274 5.51697 15.5438 5.99897 14.9856 6.703L13.5877 8.46597C13.3304 8.79054 12.8586 8.84503 12.5341 8.58768C12.2095 8.33034 12.155 7.8586 12.4123 7.53403L13.8386 5.73521C14.3718 5.06274 14.8118 4.50783 15.2055 4.11252Z"
        fill="#3F3F3F"
      />
      <path
        d="M7.75003 5C7.75003 4.58579 7.41424 4.25 7.00003 4.25C6.58581 4.25 6.25003 4.58579 6.25003 5L6.25003 17.8105C5.92737 17.483 5.54378 17.001 4.98556 16.297L3.58771 14.534C3.33036 14.2095 2.85863 14.155 2.53406 14.4123C2.20949 14.6697 2.155 15.1414 2.41235 15.466L3.8386 17.2648C4.37178 17.9373 4.81175 18.4922 5.20554 18.8875C5.60965 19.2932 6.05819 19.6294 6.63136 19.7208C6.75347 19.7402 6.87664 19.75 7.00003 19.75C7.12341 19.75 7.24659 19.7402 7.3687 19.7208C7.94187 19.6294 8.39041 19.2932 8.79452 18.8875C9.18831 18.4922 9.62828 17.9372 10.1615 17.2648L11.5877 15.466C11.8451 15.1414 11.7906 14.6697 11.466 14.4123C11.1414 14.155 10.6697 14.2095 10.4123 14.534L9.0145 16.297C8.45628 17.001 8.07269 17.483 7.75003 17.8105L7.75003 5Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Swap-2": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4805 8.95732C16.3289 8.76614 16.1985 8.6019 16.0834 8.46043V16.3333C16.0834 16.7475 15.7476 17.0833 15.3334 17.0833C14.9192 17.0833 14.5834 16.7475 14.5834 16.3333V8.46044C14.4684 8.6019 14.3379 8.76614 14.1863 8.95732L13.2544 10.1326C12.9971 10.4572 12.5253 10.5117 12.2008 10.2544C11.8762 9.997 11.8217 9.52527 12.0791 9.2007L13.0374 7.992C13.3863 7.55191 13.6873 7.17232 13.96 6.89858C14.2429 6.61458 14.5884 6.34588 15.0483 6.2726C15.1427 6.25757 15.2379 6.25 15.3334 6.25C15.4289 6.25 15.5242 6.25757 15.6185 6.2726C16.0785 6.34588 16.424 6.61458 16.7069 6.89858C16.9795 7.17232 17.2805 7.55188 17.6294 7.99197L18.5878 9.2007C18.8451 9.52527 18.7906 9.997 18.4661 10.2544C18.1415 10.5117 17.6697 10.4572 17.4124 10.1326L16.4805 8.95732Z"
        fill="#3F3F3F"
      />
      <path
        d="M9.41667 7.6665C9.41667 7.25229 9.08088 6.9165 8.66667 6.9165C8.25245 6.9165 7.91667 7.25229 7.91667 7.6665L7.91667 15.5394C7.80163 15.3979 7.67117 15.2337 7.51958 15.0425L6.58768 13.8672C6.33034 13.5426 5.8586 13.4881 5.53403 13.7455C5.20946 14.0028 5.15497 14.4746 5.41232 14.7991L6.37069 16.0079C6.71961 16.4479 7.02054 16.8275 7.29323 17.1013C7.57613 17.3853 7.92161 17.654 8.38155 17.7272C8.47591 17.7423 8.57119 17.7498 8.66667 17.7498C8.76215 17.7498 8.85742 17.7423 8.95179 17.7272C9.41173 17.654 9.7572 17.3853 10.0401 17.1013C10.3128 16.8275 10.6137 16.4479 10.9626 16.0079L11.921 14.7991C12.1784 14.4746 12.1239 14.0028 11.7993 13.7455C11.4747 13.4881 11.003 13.5426 10.7457 13.8672L9.81375 15.0425C9.66217 15.2337 9.53171 15.3979 9.41667 15.5394L9.41667 7.6665Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4635 2.37373C15.3214 2.24999 13.8818 2.24999 12.0452 2.25H11.9548C10.1182 2.24999 8.67861 2.24999 7.53648 2.37373C6.37094 2.50001 5.42656 2.76232 4.62024 3.34815C4.13209 3.70281 3.70281 4.13209 3.34815 4.62024C2.76232 5.42656 2.50001 6.37094 2.37373 7.53648C2.24999 8.67861 2.24999 10.1182 2.25 11.9548V12.0452C2.24999 13.8818 2.24999 15.3214 2.37373 16.4635C2.50001 17.6291 2.76232 18.5734 3.34815 19.3798C3.70281 19.8679 4.13209 20.2972 4.62024 20.6518C5.42656 21.2377 6.37094 21.5 7.53648 21.6263C8.67859 21.75 10.1182 21.75 11.9547 21.75H12.0453C13.8818 21.75 15.3214 21.75 16.4635 21.6263C17.6291 21.5 18.5734 21.2377 19.3798 20.6518C19.8679 20.2972 20.2972 19.8679 20.6518 19.3798C21.2377 18.5734 21.5 17.6291 21.6263 16.4635C21.75 15.3214 21.75 13.8818 21.75 12.0453V11.9547C21.75 10.1182 21.75 8.67859 21.6263 7.53648C21.5 6.37094 21.2377 5.42656 20.6518 4.62024C20.2972 4.13209 19.8679 3.70281 19.3798 3.34815C18.5734 2.76232 17.6291 2.50001 16.4635 2.37373ZM5.50191 4.56168C6.00992 4.19259 6.66013 3.97745 7.69804 3.865C8.74999 3.75103 10.1084 3.75 12 3.75C13.8916 3.75 15.25 3.75103 16.302 3.865C17.3399 3.97745 17.9901 4.19259 18.4981 4.56168C18.8589 4.82382 19.1762 5.14111 19.4383 5.50191C19.8074 6.00992 20.0225 6.66013 20.135 7.69804C20.249 8.74999 20.25 10.1084 20.25 12C20.25 13.8916 20.249 15.25 20.135 16.302C20.0225 17.3399 19.8074 17.9901 19.4383 18.4981C19.1762 18.8589 18.8589 19.1762 18.4981 19.4383C17.9901 19.8074 17.3399 20.0225 16.302 20.135C15.25 20.249 13.8916 20.25 12 20.25C10.1084 20.25 8.74999 20.249 7.69804 20.135C6.66013 20.0225 6.00992 19.8074 5.50191 19.4383C5.14111 19.1762 4.82382 18.8589 4.56168 18.4981C4.19259 17.9901 3.97745 17.3399 3.865 16.302C3.75103 15.25 3.75 13.8916 3.75 12C3.75 10.1084 3.75103 8.74999 3.865 7.69804C3.97745 6.66013 4.19259 6.00992 4.56168 5.50191C4.82382 5.14111 5.14111 4.82382 5.50191 4.56168Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  Download: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 3C12.75 2.58579 12.4142 2.25 12 2.25C11.5858 2.25 11.25 2.58579 11.25 3V15.8105C10.9273 15.483 10.5438 15.001 9.98554 14.297L8.58769 12.534C8.33034 12.2095 7.85861 12.155 7.53404 12.4123C7.20947 12.6697 7.15498 13.1414 7.41232 13.466L8.83857 15.2648C9.37175 15.9372 9.81172 16.4922 10.2055 16.8875C10.6096 17.2932 11.0582 17.6294 11.6313 17.7208C11.7534 17.7402 11.8766 17.75 12 17.75C12.1234 17.75 12.2466 17.7402 12.3687 17.7208C12.9419 17.6294 13.3904 17.2932 13.7945 16.8875C14.1883 16.4922 14.6283 15.9372 15.1614 15.2648L16.5877 13.466C16.845 13.1414 16.7905 12.6697 16.466 12.4123C16.1414 12.155 15.6697 12.2095 15.4123 12.534L14.0145 14.297C13.4563 15.001 13.0727 15.483 12.75 15.8105V3Z"
        fill="#3F3F3F"
      />
      <path
        d="M20.6 10.5496C20.3513 10.2184 19.8811 10.1516 19.5499 10.4003C19.2188 10.6491 19.1519 11.1192 19.4007 11.4504C19.9342 12.1607 20.25 13.0424 20.25 14V15C20.25 15.9577 20.2477 16.3492 20.1977 16.6648C19.9097 18.4834 18.4834 19.9096 16.6649 20.1977C16.3492 20.2477 15.9577 20.25 15 20.25H9.00001C8.04234 20.25 7.65083 20.2477 7.33516 20.1977C5.51662 19.9096 4.09036 18.4834 3.80233 16.6648C3.75234 16.3492 3.75001 15.9577 3.75001 15V14C3.75001 13.0424 4.06583 12.1607 4.59932 11.4504C4.84808 11.1192 4.78127 10.6491 4.45008 10.4003C4.11888 10.1516 3.64874 10.2184 3.39997 10.5496C2.67807 11.5106 2.25001 12.7064 2.25001 14L2.25 15.0808C2.24994 15.9328 2.2499 16.4518 2.3208 16.8995C2.71049 19.3599 4.64013 21.2895 7.10051 21.6792C7.54819 21.7501 8.06718 21.7501 8.91921 21.75H15.0808C15.9328 21.7501 16.4518 21.7501 16.8995 21.6792C19.3599 21.2895 21.2895 19.3599 21.6792 16.8995C21.7501 16.4518 21.7501 15.9328 21.75 15.0808L21.75 14C21.75 12.7064 21.3219 11.5106 20.6 10.5496Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  Upload: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2055 3.11252C10.6096 2.70685 11.0582 2.37055 11.6313 2.27923C11.7534 2.25978 11.8766 2.25 12 2.25C12.1234 2.25 12.2466 2.25978 12.3687 2.27923C12.9419 2.37055 13.3904 2.70685 13.7945 3.11252C14.1883 3.50784 14.6283 4.06275 15.1614 4.73524L16.5877 6.53403C16.845 6.8586 16.7905 7.33034 16.466 7.58768C16.1414 7.84503 15.6697 7.79054 15.4123 7.46597L14.0145 5.703C13.4563 4.99897 13.0727 4.51697 12.75 4.18952V17C12.75 17.4142 12.4142 17.75 12 17.75C11.5858 17.75 11.25 17.4142 11.25 17V4.18952C10.9274 4.51697 10.5438 4.99897 9.98554 5.703L8.58769 7.46597C8.33035 7.79054 7.85861 7.84503 7.53404 7.58768C7.20947 7.33034 7.15498 6.8586 7.41233 6.53403L8.83857 4.73525C9.37174 4.06278 9.81173 3.50783 10.2055 3.11252Z"
        fill="#3F3F3F"
      />
      <path
        d="M20.6 10.5496C20.3513 10.2184 19.8811 10.1516 19.5499 10.4003C19.2188 10.6491 19.1519 11.1192 19.4007 11.4504C19.9342 12.1607 20.25 13.0424 20.25 14V15C20.25 15.9577 20.2477 16.3492 20.1977 16.6648C19.9097 18.4834 18.4834 19.9096 16.6649 20.1977C16.3492 20.2477 15.9577 20.25 15 20.25H9.00001C8.04234 20.25 7.65083 20.2477 7.33516 20.1977C5.51662 19.9096 4.09036 18.4834 3.80233 16.6648C3.75234 16.3492 3.75001 15.9577 3.75001 15V14C3.75001 13.0424 4.06583 12.1607 4.59932 11.4504C4.84808 11.1192 4.78127 10.6491 4.45008 10.4003C4.11888 10.1516 3.64874 10.2184 3.39997 10.5496C2.67807 11.5106 2.25001 12.7064 2.25001 14L2.25 15.0808C2.24994 15.9328 2.2499 16.4518 2.3208 16.8995C2.71049 19.3599 4.64013 21.2895 7.10051 21.6792C7.54819 21.7501 8.06718 21.7501 8.91921 21.75H15.0808C15.9328 21.7501 16.4518 21.7501 16.8995 21.6792C19.3599 21.2895 21.2895 19.3599 21.6792 16.8995C21.7501 16.4518 21.7501 15.9328 21.75 15.0808L21.75 14C21.75 12.7064 21.3219 11.5106 20.6 10.5496Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Down-Square-1": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 8C12.75 7.58579 12.4142 7.25 12 7.25C11.5858 7.25 11.25 7.58579 11.25 8V14.9362C11.2358 14.9251 11.2213 14.9137 11.2065 14.9018C10.8839 14.6427 10.5003 14.2573 9.93054 13.6824L8.53269 12.272C8.24111 11.9778 7.76624 11.9757 7.47204 12.2673C7.17784 12.5589 7.17573 13.0338 7.46731 13.328L8.89566 14.7691C9.42704 15.3053 9.87023 15.7525 10.2672 16.0713C10.6837 16.4057 11.1213 16.6601 11.6545 16.7281C11.7692 16.7427 11.8846 16.75 12 16.75C12.1154 16.75 12.2308 16.7427 12.3455 16.7281C12.8787 16.6601 13.3163 16.4057 13.7328 16.0713C14.1298 15.7525 14.573 15.3053 15.1043 14.7691L16.5327 13.328C16.8243 13.0338 16.8222 12.5589 16.528 12.2673C16.2338 11.9757 15.7589 11.9778 15.4673 12.272L14.0695 13.6824C13.4997 14.2573 13.1161 14.6427 12.7935 14.9018C12.7787 14.9137 12.7642 14.9251 12.75 14.9362V8Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4635 2.37373C15.3214 2.24999 13.8818 2.24999 12.0452 2.25H11.9548C10.1182 2.24999 8.67861 2.24999 7.53648 2.37373C6.37094 2.50001 5.42656 2.76232 4.62024 3.34815C4.13209 3.70281 3.70281 4.13209 3.34815 4.62024C2.76232 5.42656 2.50001 6.37094 2.37373 7.53648C2.24999 8.67861 2.24999 10.1182 2.25 11.9548V12.0452C2.24999 13.8818 2.24999 15.3214 2.37373 16.4635C2.50001 17.6291 2.76232 18.5734 3.34815 19.3798C3.70281 19.8679 4.13209 20.2972 4.62024 20.6518C5.42656 21.2377 6.37094 21.5 7.53648 21.6263C8.67859 21.75 10.1182 21.75 11.9547 21.75H12.0453C13.8818 21.75 15.3214 21.75 16.4635 21.6263C17.6291 21.5 18.5734 21.2377 19.3798 20.6518C19.8679 20.2972 20.2972 19.8679 20.6518 19.3798C21.2377 18.5734 21.5 17.6291 21.6263 16.4635C21.75 15.3214 21.75 13.8818 21.75 12.0453V11.9547C21.75 10.1182 21.75 8.67859 21.6263 7.53648C21.5 6.37094 21.2377 5.42656 20.6518 4.62024C20.2972 4.13209 19.8679 3.70281 19.3798 3.34815C18.5734 2.76232 17.6291 2.50001 16.4635 2.37373ZM5.50191 4.56168C6.00992 4.19259 6.66013 3.97745 7.69804 3.865C8.74999 3.75103 10.1084 3.75 12 3.75C13.8916 3.75 15.25 3.75103 16.302 3.865C17.3399 3.97745 17.9901 4.19259 18.4981 4.56168C18.8589 4.82382 19.1762 5.14111 19.4383 5.50191C19.8074 6.00992 20.0225 6.66013 20.135 7.69804C20.249 8.74999 20.25 10.1084 20.25 12C20.25 13.8916 20.249 15.25 20.135 16.302C20.0225 17.3399 19.8074 17.9901 19.4383 18.4981C19.1762 18.8589 18.8589 19.1762 18.4981 19.4383C17.9901 19.8074 17.3399 20.0225 16.302 20.135C15.25 20.249 13.8916 20.25 12 20.25C10.1084 20.25 8.74999 20.249 7.69804 20.135C6.66013 20.0225 6.00992 19.8074 5.50191 19.4383C5.14111 19.1762 4.82382 18.8589 4.56168 18.4981C4.19259 17.9901 3.97745 17.3399 3.865 16.302C3.75103 15.25 3.75 13.8916 3.75 12C3.75 10.1084 3.75103 8.74999 3.865 7.69804C3.97745 6.66013 4.19259 6.00992 4.56168 5.50191C4.82382 5.14111 5.14111 4.82382 5.50191 4.56168Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Up-Square-1": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7935 9.09823C12.7787 9.08635 12.7642 9.07487 12.75 9.06379V16C12.75 16.4142 12.4142 16.75 12 16.75C11.5858 16.75 11.25 16.4142 11.25 16V9.06379C11.2358 9.07487 11.2213 9.08635 11.2065 9.09823C10.8839 9.35733 10.5003 9.74268 9.93054 10.3176L8.53269 11.728C8.24111 12.0222 7.76624 12.0243 7.47204 11.7327C7.17784 11.4411 7.17573 10.9662 7.46731 10.672L8.89565 9.2309C9.42704 8.69472 9.87022 8.24753 10.2672 7.92869C10.6837 7.59426 11.1213 7.33989 11.6545 7.27193C11.7692 7.25731 11.8846 7.25 12 7.25C12.1154 7.25 12.2308 7.25731 12.3455 7.27193C12.8787 7.33989 13.3163 7.59427 13.7328 7.92869C14.1298 8.24753 14.5729 8.69469 15.1043 9.23084L16.5327 10.672C16.8243 10.9662 16.8222 11.4411 16.528 11.7327C16.2338 12.0243 15.7589 12.0222 15.4673 11.728L14.0695 10.3176C13.4997 9.74268 13.1161 9.35733 12.7935 9.09823Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4635 2.37373C15.3214 2.24999 13.8818 2.24999 12.0452 2.25H11.9548C10.1182 2.24999 8.67861 2.24999 7.53648 2.37373C6.37094 2.50001 5.42656 2.76232 4.62024 3.34815C4.13209 3.70281 3.70281 4.13209 3.34815 4.62024C2.76232 5.42656 2.50001 6.37094 2.37373 7.53648C2.24999 8.67861 2.24999 10.1182 2.25 11.9548V12.0452C2.24999 13.8818 2.24999 15.3214 2.37373 16.4635C2.50001 17.6291 2.76232 18.5734 3.34815 19.3798C3.70281 19.8679 4.13209 20.2972 4.62024 20.6518C5.42656 21.2377 6.37094 21.5 7.53648 21.6263C8.67859 21.75 10.1182 21.75 11.9547 21.75H12.0453C13.8818 21.75 15.3214 21.75 16.4635 21.6263C17.6291 21.5 18.5734 21.2377 19.3798 20.6518C19.8679 20.2972 20.2972 19.8679 20.6518 19.3798C21.2377 18.5734 21.5 17.6291 21.6263 16.4635C21.75 15.3214 21.75 13.8818 21.75 12.0453V11.9547C21.75 10.1182 21.75 8.67859 21.6263 7.53648C21.5 6.37094 21.2377 5.42656 20.6518 4.62024C20.2972 4.13209 19.8679 3.70281 19.3798 3.34815C18.5734 2.76232 17.6291 2.50001 16.4635 2.37373ZM5.50191 4.56168C6.00992 4.19259 6.66013 3.97745 7.69804 3.865C8.74999 3.75103 10.1084 3.75 12 3.75C13.8916 3.75 15.25 3.75103 16.302 3.865C17.3399 3.97745 17.9901 4.19259 18.4981 4.56168C18.8589 4.82382 19.1762 5.14111 19.4383 5.50191C19.8074 6.00992 20.0225 6.66013 20.135 7.69804C20.249 8.74999 20.25 10.1084 20.25 12C20.25 13.8916 20.249 15.25 20.135 16.302C20.0225 17.3399 19.8074 17.9901 19.4383 18.4981C19.1762 18.8589 18.8589 19.1762 18.4981 19.4383C17.9901 19.8074 17.3399 20.0225 16.302 20.135C15.25 20.249 13.8916 20.25 12 20.25C10.1084 20.25 8.74999 20.249 7.69804 20.135C6.66013 20.0225 6.00992 19.8074 5.50191 19.4383C5.14111 19.1762 4.82382 18.8589 4.56168 18.4981C4.19259 17.9901 3.97745 17.3399 3.865 16.302C3.75103 15.25 3.75 13.8916 3.75 12C3.75 10.1084 3.75103 8.74999 3.865 7.69804C3.97745 6.66013 4.19259 6.00992 4.56168 5.50191C4.82382 5.14111 5.14111 4.82382 5.50191 4.56168Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Left-Square-1": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.728 8.53269C12.0222 8.24111 12.0243 7.76624 11.7327 7.47204C11.4411 7.17784 10.9662 7.17573 10.672 7.46731L9.23091 8.89563C8.69474 9.42702 8.24754 9.87022 7.92869 10.2672C7.59427 10.6837 7.33989 11.1213 7.27193 11.6545C7.25731 11.7692 7.25 11.8846 7.25 12C7.25 12.1154 7.25731 12.2308 7.27193 12.3455C7.33989 12.8787 7.59426 13.3163 7.92869 13.7328C8.24753 14.1298 8.69472 14.573 9.2309 15.1044L10.672 16.5327C10.9662 16.8243 11.4411 16.8222 11.7327 16.528C12.0243 16.2338 12.0222 15.7589 11.728 15.4673L10.3176 14.0695C9.74268 13.4997 9.35733 13.1161 9.09823 12.7935C9.09526 12.7898 9.09231 12.7861 9.08939 12.7825C9.08063 12.7715 9.0721 12.7607 9.06379 12.75H16C16.4142 12.75 16.75 12.4142 16.75 12C16.75 11.5858 16.4142 11.25 16 11.25H9.06379C9.07487 11.2358 9.08635 11.2213 9.09823 11.2065C9.35733 10.8839 9.74268 10.5003 10.3176 9.93054L11.728 8.53269Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4635 2.37373C15.3214 2.24999 13.8818 2.24999 12.0452 2.25H11.9548C10.1182 2.24999 8.67861 2.24999 7.53648 2.37373C6.37094 2.50001 5.42656 2.76232 4.62024 3.34815C4.13209 3.70281 3.70281 4.13209 3.34815 4.62024C2.76232 5.42656 2.50001 6.37094 2.37373 7.53648C2.24999 8.67861 2.24999 10.1182 2.25 11.9548V12.0452C2.24999 13.8818 2.24999 15.3214 2.37373 16.4635C2.50001 17.6291 2.76232 18.5734 3.34815 19.3798C3.70281 19.8679 4.13209 20.2972 4.62024 20.6518C5.42656 21.2377 6.37094 21.5 7.53648 21.6263C8.67859 21.75 10.1182 21.75 11.9547 21.75H12.0453C13.8818 21.75 15.3214 21.75 16.4635 21.6263C17.6291 21.5 18.5734 21.2377 19.3798 20.6518C19.8679 20.2972 20.2972 19.8679 20.6518 19.3798C21.2377 18.5734 21.5 17.6291 21.6263 16.4635C21.75 15.3214 21.75 13.8818 21.75 12.0453V11.9547C21.75 10.1182 21.75 8.67859 21.6263 7.53648C21.5 6.37094 21.2377 5.42656 20.6518 4.62024C20.2972 4.13209 19.8679 3.70281 19.3798 3.34815C18.5734 2.76232 17.6291 2.50001 16.4635 2.37373ZM5.50191 4.56168C6.00992 4.19259 6.66013 3.97745 7.69804 3.865C8.74999 3.75103 10.1084 3.75 12 3.75C13.8916 3.75 15.25 3.75103 16.302 3.865C17.3399 3.97745 17.9901 4.19259 18.4981 4.56168C18.8589 4.82382 19.1762 5.14111 19.4383 5.50191C19.8074 6.00992 20.0225 6.66013 20.135 7.69804C20.249 8.74999 20.25 10.1084 20.25 12C20.25 13.8916 20.249 15.25 20.135 16.302C20.0225 17.3399 19.8074 17.9901 19.4383 18.4981C19.1762 18.8589 18.8589 19.1762 18.4981 19.4383C17.9901 19.8074 17.3399 20.0225 16.302 20.135C15.25 20.249 13.8916 20.25 12 20.25C10.1084 20.25 8.74999 20.249 7.69804 20.135C6.66013 20.0225 6.00992 19.8074 5.50191 19.4383C5.14111 19.1762 4.82382 18.8589 4.56168 18.4981C4.19259 17.9901 3.97745 17.3399 3.865 16.302C3.75103 15.25 3.75 13.8916 3.75 12C3.75 10.1084 3.75103 8.74999 3.865 7.69804C3.97745 6.66013 4.19259 6.00992 4.56168 5.50191C4.82382 5.14111 5.14111 4.82382 5.50191 4.56168Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Right-Square-1": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.328 7.46731C13.0338 7.17572 12.5589 7.17784 12.2673 7.47204C11.9757 7.76624 11.9778 8.24111 12.272 8.53269L13.6824 9.93054C14.2573 10.5003 14.6427 10.8839 14.9018 11.2065C14.9137 11.2213 14.9251 11.2358 14.9362 11.25L8 11.25C7.58579 11.25 7.25 11.5858 7.25 12C7.25 12.4142 7.58579 12.75 8 12.75L14.9362 12.75C14.9251 12.7642 14.9137 12.7787 14.9018 12.7935C14.6427 13.1161 14.2573 13.4997 13.6824 14.0695L12.272 15.4673C11.9778 15.7589 11.9757 16.2338 12.2673 16.528C12.5589 16.8222 13.0338 16.8243 13.328 16.5327L14.7691 15.1043C15.3053 14.573 15.7525 14.1298 16.0713 13.7328C16.4057 13.3163 16.6601 12.8787 16.7281 12.3455C16.7427 12.2308 16.75 12.1154 16.75 12C16.75 11.8846 16.7427 11.7692 16.7281 11.6545C16.6601 11.1213 16.4057 10.6837 16.0713 10.2672C15.7525 9.87023 15.3053 9.42705 14.7691 8.89568L13.328 7.46731Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4635 2.37373C15.3214 2.24999 13.8818 2.24999 12.0452 2.25H11.9548C10.1182 2.24999 8.67861 2.24999 7.53648 2.37373C6.37094 2.50001 5.42656 2.76232 4.62024 3.34815C4.13209 3.70281 3.70281 4.13209 3.34815 4.62024C2.76232 5.42656 2.50001 6.37094 2.37373 7.53648C2.24999 8.67861 2.24999 10.1182 2.25 11.9548V12.0452C2.24999 13.8818 2.24999 15.3214 2.37373 16.4635C2.50001 17.6291 2.76232 18.5734 3.34815 19.3798C3.70281 19.8679 4.13209 20.2972 4.62024 20.6518C5.42656 21.2377 6.37094 21.5 7.53648 21.6263C8.67859 21.75 10.1182 21.75 11.9547 21.75H12.0453C13.8818 21.75 15.3214 21.75 16.4635 21.6263C17.6291 21.5 18.5734 21.2377 19.3798 20.6518C19.8679 20.2972 20.2972 19.8679 20.6518 19.3798C21.2377 18.5734 21.5 17.6291 21.6263 16.4635C21.75 15.3214 21.75 13.8818 21.75 12.0453V11.9547C21.75 10.1182 21.75 8.67859 21.6263 7.53648C21.5 6.37094 21.2377 5.42656 20.6518 4.62024C20.2972 4.13209 19.8679 3.70281 19.3798 3.34815C18.5734 2.76232 17.6291 2.50001 16.4635 2.37373ZM5.50191 4.56168C6.00992 4.19259 6.66013 3.97745 7.69804 3.865C8.74999 3.75103 10.1084 3.75 12 3.75C13.8916 3.75 15.25 3.75103 16.302 3.865C17.3399 3.97745 17.9901 4.19259 18.4981 4.56168C18.8589 4.82382 19.1762 5.14111 19.4383 5.50191C19.8074 6.00992 20.0225 6.66013 20.135 7.69804C20.249 8.74999 20.25 10.1084 20.25 12C20.25 13.8916 20.249 15.25 20.135 16.302C20.0225 17.3399 19.8074 17.9901 19.4383 18.4981C19.1762 18.8589 18.8589 19.1762 18.4981 19.4383C17.9901 19.8074 17.3399 20.0225 16.302 20.135C15.25 20.249 13.8916 20.25 12 20.25C10.1084 20.25 8.74999 20.249 7.69804 20.135C6.66013 20.0225 6.00992 19.8074 5.50191 19.4383C5.14111 19.1762 4.82382 18.8589 4.56168 18.4981C4.19259 17.9901 3.97745 17.3399 3.865 16.302C3.75103 15.25 3.75 13.8916 3.75 12C3.75 10.1084 3.75103 8.74999 3.865 7.69804C3.97745 6.66013 4.19259 6.00992 4.56168 5.50191C4.82382 5.14111 5.14111 4.82382 5.50191 4.56168Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Down-Square-2": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5327 10.528C17.8243 10.2338 17.8222 9.75889 17.528 9.46731C17.2338 9.17572 16.7589 9.17784 16.4673 9.47204L14.72 11.235C14.0109 11.9505 13.5228 12.4413 13.1093 12.7734C12.7076 13.096 12.4496 13.2067 12.2185 13.2361C12.0734 13.2546 11.9266 13.2546 11.7815 13.2361C11.5504 13.2067 11.2924 13.096 10.8907 12.7734C10.4772 12.4413 9.98914 11.9505 9.28 11.235L7.53269 9.47204C7.24111 9.17785 6.76624 9.17573 6.47204 9.46731C6.17784 9.75889 6.17573 10.2338 6.46731 10.528L8.2461 12.3227C8.91603 12.9987 9.46359 13.5511 9.95146 13.9429C10.4588 14.3504 10.9737 14.6453 11.5918 14.7241C11.8629 14.7586 12.1371 14.7586 12.4082 14.7241C13.0263 14.6453 13.5412 14.3504 14.0485 13.9429C14.5364 13.5511 15.0839 12.9987 15.7539 12.3227L17.5327 10.528Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4635 2.37373C15.3214 2.24999 13.8818 2.24999 12.0452 2.25H11.9548C10.1182 2.24999 8.67861 2.24999 7.53648 2.37373C6.37094 2.50001 5.42656 2.76232 4.62024 3.34815C4.13209 3.70281 3.70281 4.13209 3.34815 4.62024C2.76232 5.42656 2.50001 6.37094 2.37373 7.53648C2.24999 8.67861 2.24999 10.1182 2.25 11.9548V12.0452C2.24999 13.8818 2.24999 15.3214 2.37373 16.4635C2.50001 17.6291 2.76232 18.5734 3.34815 19.3798C3.70281 19.8679 4.13209 20.2972 4.62024 20.6518C5.42656 21.2377 6.37094 21.5 7.53648 21.6263C8.67859 21.75 10.1182 21.75 11.9547 21.75H12.0453C13.8818 21.75 15.3214 21.75 16.4635 21.6263C17.6291 21.5 18.5734 21.2377 19.3798 20.6518C19.8679 20.2972 20.2972 19.8679 20.6518 19.3798C21.2377 18.5734 21.5 17.6291 21.6263 16.4635C21.75 15.3214 21.75 13.8818 21.75 12.0453V11.9547C21.75 10.1182 21.75 8.67859 21.6263 7.53648C21.5 6.37094 21.2377 5.42656 20.6518 4.62024C20.2972 4.13209 19.8679 3.70281 19.3798 3.34815C18.5734 2.76232 17.6291 2.50001 16.4635 2.37373ZM5.50191 4.56168C6.00992 4.19259 6.66013 3.97745 7.69804 3.865C8.74999 3.75103 10.1084 3.75 12 3.75C13.8916 3.75 15.25 3.75103 16.302 3.865C17.3399 3.97745 17.9901 4.19259 18.4981 4.56168C18.8589 4.82382 19.1762 5.14111 19.4383 5.50191C19.8074 6.00992 20.0225 6.66013 20.135 7.69804C20.249 8.74999 20.25 10.1084 20.25 12C20.25 13.8916 20.249 15.25 20.135 16.302C20.0225 17.3399 19.8074 17.9901 19.4383 18.4981C19.1762 18.8589 18.8589 19.1762 18.4981 19.4383C17.9901 19.8074 17.3399 20.0225 16.302 20.135C15.25 20.249 13.8916 20.25 12 20.25C10.1084 20.25 8.74999 20.249 7.69804 20.135C6.66013 20.0225 6.00992 19.8074 5.50191 19.4383C5.14111 19.1762 4.82382 18.8589 4.56168 18.4981C4.19259 17.9901 3.97745 17.3399 3.865 16.302C3.75103 15.25 3.75 13.8916 3.75 12C3.75 10.1084 3.75103 8.74999 3.865 7.69804C3.97745 6.66013 4.19259 6.00992 4.56168 5.50191C4.82382 5.14111 5.14111 4.82382 5.50191 4.56168Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Up-Square-2": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.28 12.765C9.98914 12.0495 10.4772 11.5587 10.8907 11.2266C11.2924 10.904 11.5504 10.7933 11.7815 10.7639C11.9266 10.7454 12.0734 10.7454 12.2185 10.7639C12.4496 10.7933 12.7076 10.904 13.1093 11.2266C13.5228 11.5587 14.0109 12.0495 14.72 12.765L16.4673 14.528C16.7589 14.8222 17.2338 14.8243 17.528 14.5327C17.8222 14.2411 17.8243 13.7662 17.5327 13.472L15.7539 11.6773C15.0839 11.0013 14.5364 10.4489 14.0485 10.0571C13.5412 9.64963 13.0263 9.3547 12.4082 9.27591C12.1371 9.24136 11.8629 9.24136 11.5918 9.27591C10.9737 9.3547 10.4588 9.64963 9.95146 10.0571C9.46359 10.4489 8.91605 11.0013 8.24612 11.6773L6.46731 13.472C6.17573 13.7662 6.17784 14.2411 6.47204 14.5327C6.76624 14.8243 7.24111 14.8222 7.53269 14.528L9.28 12.765Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4635 2.37373C15.3214 2.24999 13.8818 2.24999 12.0452 2.25H11.9548C10.1182 2.24999 8.67861 2.24999 7.53648 2.37373C6.37094 2.50001 5.42656 2.76232 4.62024 3.34815C4.13209 3.70281 3.70281 4.13209 3.34815 4.62024C2.76232 5.42656 2.50001 6.37094 2.37373 7.53648C2.24999 8.67861 2.24999 10.1182 2.25 11.9548V12.0452C2.24999 13.8818 2.24999 15.3214 2.37373 16.4635C2.50001 17.6291 2.76232 18.5734 3.34815 19.3798C3.70281 19.8679 4.13209 20.2972 4.62024 20.6518C5.42656 21.2377 6.37094 21.5 7.53648 21.6263C8.67859 21.75 10.1182 21.75 11.9547 21.75H12.0453C13.8818 21.75 15.3214 21.75 16.4635 21.6263C17.6291 21.5 18.5734 21.2377 19.3798 20.6518C19.8679 20.2972 20.2972 19.8679 20.6518 19.3798C21.2377 18.5734 21.5 17.6291 21.6263 16.4635C21.75 15.3214 21.75 13.8818 21.75 12.0453V11.9547C21.75 10.1182 21.75 8.67859 21.6263 7.53648C21.5 6.37094 21.2377 5.42656 20.6518 4.62024C20.2972 4.13209 19.8679 3.70281 19.3798 3.34815C18.5734 2.76232 17.6291 2.50001 16.4635 2.37373ZM5.50191 4.56168C6.00992 4.19259 6.66013 3.97745 7.69804 3.865C8.74999 3.75103 10.1084 3.75 12 3.75C13.8916 3.75 15.25 3.75103 16.302 3.865C17.3399 3.97745 17.9901 4.19259 18.4981 4.56168C18.8589 4.82382 19.1762 5.14111 19.4383 5.50191C19.8074 6.00992 20.0225 6.66013 20.135 7.69804C20.249 8.74999 20.25 10.1084 20.25 12C20.25 13.8916 20.249 15.25 20.135 16.302C20.0225 17.3399 19.8074 17.9901 19.4383 18.4981C19.1762 18.8589 18.8589 19.1762 18.4981 19.4383C17.9901 19.8074 17.3399 20.0225 16.302 20.135C15.25 20.249 13.8916 20.25 12 20.25C10.1084 20.25 8.74999 20.249 7.69804 20.135C6.66013 20.0225 6.00992 19.8074 5.50191 19.4383C5.14111 19.1762 4.82382 18.8589 4.56168 18.4981C4.19259 17.9901 3.97745 17.3399 3.865 16.302C3.75103 15.25 3.75 13.8916 3.75 12C3.75 10.1084 3.75103 8.74999 3.865 7.69804C3.97745 6.66013 4.19259 6.00992 4.56168 5.50191C4.82382 5.14111 5.14111 4.82382 5.50191 4.56168Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Left-Square-2": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.528 7.5327C14.8222 7.24111 14.8243 6.76624 14.5327 6.47205C14.2411 6.17785 13.7662 6.17573 13.472 6.46732L11.6773 8.24609C11.0013 8.91604 10.4489 9.46359 10.0571 9.95146C9.64963 10.4588 9.3547 10.9737 9.27591 11.5918C9.24137 11.8629 9.24137 12.1371 9.27591 12.4082C9.3547 13.0263 9.64963 13.5412 10.0571 14.0485C10.4489 14.5364 11.0013 15.084 11.6773 15.7539L13.472 17.5327C13.7662 17.8243 14.2411 17.8222 14.5327 17.528C14.8243 17.2338 14.8222 16.7589 14.528 16.4673L12.765 14.72C12.0495 14.0109 11.5587 13.5228 11.2266 13.1093C10.904 12.7076 10.7933 12.4496 10.7639 12.2185C10.7454 12.0734 10.7454 11.9266 10.7639 11.7815C10.7933 11.5504 10.904 11.2924 11.2266 10.8907C11.5587 10.4772 12.0495 9.98914 12.765 9.28001L14.528 7.5327Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4635 2.37373C15.3214 2.24999 13.8818 2.24999 12.0452 2.25H11.9548C10.1182 2.24999 8.67861 2.24999 7.53648 2.37373C6.37094 2.50001 5.42656 2.76232 4.62024 3.34815C4.13209 3.70281 3.70281 4.13209 3.34815 4.62024C2.76232 5.42656 2.50001 6.37094 2.37373 7.53648C2.24999 8.67861 2.24999 10.1182 2.25 11.9548V12.0452C2.24999 13.8818 2.24999 15.3214 2.37373 16.4635C2.50001 17.6291 2.76232 18.5734 3.34815 19.3798C3.70281 19.8679 4.13209 20.2972 4.62024 20.6518C5.42656 21.2377 6.37094 21.5 7.53648 21.6263C8.67859 21.75 10.1182 21.75 11.9547 21.75H12.0453C13.8818 21.75 15.3214 21.75 16.4635 21.6263C17.6291 21.5 18.5734 21.2377 19.3798 20.6518C19.8679 20.2972 20.2972 19.8679 20.6518 19.3798C21.2377 18.5734 21.5 17.6291 21.6263 16.4635C21.75 15.3214 21.75 13.8818 21.75 12.0453V11.9547C21.75 10.1182 21.75 8.67859 21.6263 7.53648C21.5 6.37094 21.2377 5.42656 20.6518 4.62024C20.2972 4.13209 19.8679 3.70281 19.3798 3.34815C18.5734 2.76232 17.6291 2.50001 16.4635 2.37373ZM5.50191 4.56168C6.00992 4.19259 6.66013 3.97745 7.69804 3.865C8.74999 3.75103 10.1084 3.75 12 3.75C13.8916 3.75 15.25 3.75103 16.302 3.865C17.3399 3.97745 17.9901 4.19259 18.4981 4.56168C18.8589 4.82382 19.1762 5.14111 19.4383 5.50191C19.8074 6.00992 20.0225 6.66013 20.135 7.69804C20.249 8.74999 20.25 10.1084 20.25 12C20.25 13.8916 20.249 15.25 20.135 16.302C20.0225 17.3399 19.8074 17.9901 19.4383 18.4981C19.1762 18.8589 18.8589 19.1762 18.4981 19.4383C17.9901 19.8074 17.3399 20.0225 16.302 20.135C15.25 20.249 13.8916 20.25 12 20.25C10.1084 20.25 8.74999 20.249 7.69804 20.135C6.66013 20.0225 6.00992 19.8074 5.50191 19.4383C5.14111 19.1762 4.82382 18.8589 4.56168 18.4981C4.19259 17.9901 3.97745 17.3399 3.865 16.302C3.75103 15.25 3.75 13.8916 3.75 12C3.75 10.1084 3.75103 8.74999 3.865 7.69804C3.97745 6.66013 4.19259 6.00992 4.56168 5.50191C4.82382 5.14111 5.14111 4.82382 5.50191 4.56168Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Right-Square-2": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.528 6.46731C10.2338 6.17573 9.75889 6.17784 9.46731 6.47204C9.17573 6.76624 9.17784 7.24111 9.47204 7.53269L11.235 9.28C11.9505 9.98914 12.4413 10.4772 12.7734 10.8907C13.096 11.2924 13.2067 11.5504 13.2361 11.7815C13.2546 11.9266 13.2546 12.0734 13.2361 12.2185C13.2067 12.4496 13.096 12.7076 12.7734 13.1093C12.4413 13.5228 11.9505 14.0109 11.235 14.72L9.47204 16.4673C9.17784 16.7589 9.17573 17.2338 9.46731 17.528C9.75889 17.8222 10.2338 17.8243 10.528 17.5327L12.3227 15.7539C12.9987 15.084 13.5511 14.5364 13.9429 14.0485C14.3504 13.5412 14.6453 13.0263 14.7241 12.4082C14.7586 12.1371 14.7586 11.8629 14.7241 11.5918C14.6453 10.9737 14.3504 10.4588 13.9429 9.95146C13.5511 9.46359 12.9987 8.91605 12.3227 8.24612L10.528 6.46731Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4635 2.37373C15.3214 2.24999 13.8818 2.24999 12.0452 2.25H11.9548C10.1182 2.24999 8.67861 2.24999 7.53648 2.37373C6.37094 2.50001 5.42656 2.76232 4.62024 3.34815C4.13209 3.70281 3.70281 4.13209 3.34815 4.62024C2.76232 5.42656 2.50001 6.37094 2.37373 7.53648C2.24999 8.67861 2.24999 10.1182 2.25 11.9548V12.0452C2.24999 13.8818 2.24999 15.3214 2.37373 16.4635C2.50001 17.6291 2.76232 18.5734 3.34815 19.3798C3.70281 19.8679 4.13209 20.2972 4.62024 20.6518C5.42656 21.2377 6.37094 21.5 7.53648 21.6263C8.67859 21.75 10.1182 21.75 11.9547 21.75H12.0453C13.8818 21.75 15.3214 21.75 16.4635 21.6263C17.6291 21.5 18.5734 21.2377 19.3798 20.6518C19.8679 20.2972 20.2972 19.8679 20.6518 19.3798C21.2377 18.5734 21.5 17.6291 21.6263 16.4635C21.75 15.3214 21.75 13.8818 21.75 12.0453V11.9547C21.75 10.1182 21.75 8.67859 21.6263 7.53648C21.5 6.37094 21.2377 5.42656 20.6518 4.62024C20.2972 4.13209 19.8679 3.70281 19.3798 3.34815C18.5734 2.76232 17.6291 2.50001 16.4635 2.37373ZM5.50191 4.56168C6.00992 4.19259 6.66013 3.97745 7.69804 3.865C8.74999 3.75103 10.1084 3.75 12 3.75C13.8916 3.75 15.25 3.75103 16.302 3.865C17.3399 3.97745 17.9901 4.19259 18.4981 4.56168C18.8589 4.82382 19.1762 5.14111 19.4383 5.50191C19.8074 6.00992 20.0225 6.66013 20.135 7.69804C20.249 8.74999 20.25 10.1084 20.25 12C20.25 13.8916 20.249 15.25 20.135 16.302C20.0225 17.3399 19.8074 17.9901 19.4383 18.4981C19.1762 18.8589 18.8589 19.1762 18.4981 19.4383C17.9901 19.8074 17.3399 20.0225 16.302 20.135C15.25 20.249 13.8916 20.25 12 20.25C10.1084 20.25 8.74999 20.249 7.69804 20.135C6.66013 20.0225 6.00992 19.8074 5.50191 19.4383C5.14111 19.1762 4.82382 18.8589 4.56168 18.4981C4.19259 17.9901 3.97745 17.3399 3.865 16.302C3.75103 15.25 3.75 13.8916 3.75 12C3.75 10.1084 3.75103 8.74999 3.865 7.69804C3.97745 6.66013 4.19259 6.00992 4.56168 5.50191C4.82382 5.14111 5.14111 4.82382 5.50191 4.56168Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Down-Circle-1": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 8C12.75 7.58579 12.4142 7.25 12 7.25C11.5858 7.25 11.25 7.58579 11.25 8V14.9362C11.2358 14.9251 11.2213 14.9137 11.2065 14.9018C10.8839 14.6427 10.5003 14.2573 9.93054 13.6824L8.53269 12.272C8.24111 11.9778 7.76624 11.9757 7.47204 12.2673C7.17784 12.5589 7.17573 13.0338 7.46731 13.328L8.89566 14.7691C9.42703 15.3053 9.87023 15.7525 10.2672 16.0713C10.6837 16.4057 11.1213 16.6601 11.6545 16.7281C11.7692 16.7427 11.8846 16.75 12 16.75C12.1154 16.75 12.2308 16.7427 12.3455 16.7281C12.8787 16.6601 13.3163 16.4057 13.7328 16.0713C14.1298 15.7525 14.573 15.3053 15.1044 14.7691L16.5327 13.328C16.8243 13.0338 16.8222 12.5589 16.528 12.2673C16.2338 11.9757 15.7589 11.9778 15.4673 12.272L14.0695 13.6824C13.4997 14.2573 13.1161 14.6427 12.7935 14.9018C12.7898 14.9047 12.7861 14.9077 12.7825 14.9106C12.7715 14.9194 12.7607 14.9279 12.75 14.9362V8Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Up-Circle-1": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7935 9.09823C12.7787 9.08635 12.7642 9.07487 12.75 9.06379V16C12.75 16.4142 12.4142 16.75 12 16.75C11.5858 16.75 11.25 16.4142 11.25 16V9.06379C11.2358 9.07487 11.2213 9.08635 11.2065 9.09823C10.8839 9.35733 10.5003 9.74268 9.93054 10.3176L8.53269 11.728C8.24111 12.0222 7.76624 12.0243 7.47204 11.7327C7.17784 11.4411 7.17573 10.9662 7.46731 10.672L8.89565 9.2309C9.42704 8.69472 9.87022 8.24753 10.2672 7.92869C10.6837 7.59426 11.1213 7.33989 11.6545 7.27193C11.8839 7.24269 12.1161 7.24269 12.3455 7.27193C12.8787 7.33989 13.3163 7.59427 13.7328 7.92869C14.1298 8.24753 14.5729 8.69469 15.1043 9.23085L16.5327 10.672C16.8243 10.9662 16.8222 11.4411 16.528 11.7327C16.2338 12.0243 15.7589 12.0222 15.4673 11.728L14.0695 10.3176C13.4997 9.74268 13.1161 9.35733 12.7935 9.09823Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Left-Circle-1": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.728 8.53269C12.0222 8.24111 12.0243 7.76624 11.7327 7.47204C11.4411 7.17784 10.9662 7.17573 10.672 7.46731L9.23088 8.89566C8.69471 9.42704 8.24753 9.87023 7.92869 10.2672C7.59427 10.6837 7.33989 11.1213 7.27193 11.6545C7.25731 11.7692 7.25 11.8846 7.25 12C7.25 12.1154 7.25731 12.2308 7.27193 12.3455C7.33989 12.8787 7.59426 13.3163 7.92869 13.7328C8.24753 14.1298 8.69472 14.573 9.2309 15.1044L10.672 16.5327C10.9662 16.8243 11.4411 16.8222 11.7327 16.528C12.0243 16.2338 12.0222 15.7589 11.728 15.4673L10.3176 14.0695C9.74268 13.4997 9.35733 13.1161 9.09823 12.7935C9.08635 12.7787 9.07487 12.7642 9.06379 12.75H16C16.4142 12.75 16.75 12.4142 16.75 12C16.75 11.5858 16.4142 11.25 16 11.25H9.06379C9.07487 11.2358 9.08635 11.2213 9.09823 11.2065C9.35733 10.8839 9.74268 10.5003 10.3176 9.93054L11.728 8.53269Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Right-Circle-1": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.328 7.46731C13.0338 7.17572 12.5589 7.17784 12.2673 7.47204C11.9757 7.76624 11.9778 8.24111 12.272 8.53269L13.6824 9.93054C14.2573 10.5003 14.6427 10.8839 14.9018 11.2065C14.9137 11.2213 14.9251 11.2358 14.9362 11.25L8 11.25C7.58579 11.25 7.25 11.5858 7.25 12C7.25 12.4142 7.58579 12.75 8 12.75L14.9362 12.75C14.9251 12.7642 14.9137 12.7787 14.9018 12.7935C14.6427 13.1161 14.2573 13.4997 13.6824 14.0695L12.272 15.4673C11.9778 15.7589 11.9757 16.2338 12.2673 16.528C12.5589 16.8222 13.0338 16.8243 13.328 16.5327L14.7691 15.1043C15.3053 14.573 15.7525 14.1298 16.0713 13.7328C16.4057 13.3163 16.6601 12.8787 16.7281 12.3455C16.7427 12.2308 16.75 12.1154 16.75 12C16.75 11.8846 16.7427 11.7692 16.7281 11.6545C16.6601 11.1213 16.4057 10.6837 16.0713 10.2672C15.7525 9.87023 15.3053 9.42705 14.7691 8.89568L13.328 7.46731Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Down-Circle-2": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5327 10.528C17.8243 10.2338 17.8222 9.75889 17.528 9.46731C17.2338 9.17572 16.7589 9.17784 16.4673 9.47204L14.72 11.235C14.0109 11.9505 13.5228 12.4413 13.1093 12.7734C12.7076 13.096 12.4496 13.2067 12.2185 13.2361C12.0734 13.2546 11.9266 13.2546 11.7815 13.2361C11.5504 13.2067 11.2924 13.096 10.8907 12.7734C10.4772 12.4413 9.98914 11.9505 9.28 11.235L7.53269 9.47204C7.24111 9.17784 6.76624 9.17573 6.47204 9.46731C6.17784 9.75889 6.17573 10.2338 6.46731 10.528L8.2461 12.3227C8.91603 12.9987 9.46359 13.5511 9.95146 13.9429C10.4588 14.3504 10.9737 14.6453 11.5918 14.7241C11.8629 14.7586 12.1371 14.7586 12.4082 14.7241C13.0263 14.6453 13.5412 14.3504 14.0485 13.9429C14.5364 13.5511 15.0839 12.9987 15.7539 12.3227L17.5327 10.528Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Up-Circle-2": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.28 12.765C9.98914 12.0495 10.4772 11.5587 10.8907 11.2266C11.2924 10.904 11.5504 10.7933 11.7815 10.7639C11.9266 10.7454 12.0734 10.7454 12.2185 10.7639C12.4496 10.7933 12.7076 10.904 13.1093 11.2266C13.5228 11.5587 14.0109 12.0495 14.72 12.765L16.4673 14.528C16.7589 14.8222 17.2338 14.8243 17.528 14.5327C17.8222 14.2411 17.8243 13.7662 17.5327 13.472L15.7539 11.6773C15.084 11.0014 14.5364 10.4489 14.0485 10.0571C13.5412 9.64963 13.0263 9.35469 12.4082 9.27591C12.1371 9.24136 11.8629 9.24136 11.5918 9.27591C10.9737 9.35469 10.4588 9.64963 9.95146 10.0571C9.46359 10.4489 8.91605 11.0013 8.24611 11.6773L6.46731 13.472C6.17573 13.7662 6.17785 14.2411 6.47204 14.5327C6.76624 14.8243 7.24111 14.8222 7.53269 14.528L9.28 12.765Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Left-Circle-2": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.528 7.5327C14.8222 7.24111 14.8243 6.76624 14.5327 6.47205C14.2411 6.17785 13.7662 6.17573 13.472 6.46732L11.6773 8.24609C11.0013 8.91604 10.4489 9.46359 10.0571 9.95146C9.64963 10.4588 9.3547 10.9737 9.27591 11.5918C9.24137 11.8629 9.24137 12.1371 9.27591 12.4082C9.3547 13.0263 9.64963 13.5412 10.0571 14.0485C10.4489 14.5364 11.0013 15.0839 11.6773 15.7539L13.472 17.5327C13.7662 17.8243 14.2411 17.8222 14.5327 17.528C14.8243 17.2338 14.8222 16.7589 14.528 16.4673L12.765 14.72C12.0495 14.0109 11.5587 13.5228 11.2266 13.1093C10.904 12.7076 10.7933 12.4496 10.7639 12.2185C10.7454 12.0734 10.7454 11.9266 10.7639 11.7815C10.7933 11.5504 10.904 11.2924 11.2266 10.8907C11.5587 10.4772 12.0495 9.98914 12.765 9.28001L14.528 7.5327Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Right-Circle-2": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.528 6.46731C10.2338 6.17573 9.75889 6.17784 9.46731 6.47204C9.17573 6.76624 9.17784 7.24111 9.47204 7.53269L11.235 9.28C11.9505 9.98914 12.4413 10.4772 12.7734 10.8907C13.096 11.2924 13.2067 11.5504 13.2361 11.7815C13.2546 11.9266 13.2546 12.0734 13.2361 12.2185C13.2067 12.4496 13.096 12.7076 12.7734 13.1093C12.4413 13.5228 11.9505 14.0109 11.235 14.72L9.47204 16.4673C9.17784 16.7589 9.17573 17.2338 9.46731 17.528C9.75889 17.8222 10.2338 17.8243 10.528 17.5327L12.3227 15.7539C12.9987 15.084 13.5511 14.5364 13.9429 14.0485C14.3504 13.5412 14.6453 13.0263 14.7241 12.4082C14.7586 12.1371 14.7586 11.8629 14.7241 11.5918C14.6453 10.9737 14.3504 10.4588 13.9429 9.95146C13.5511 9.46359 12.9987 8.91605 12.3227 8.24611L10.528 6.46731Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Down-1": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 4C12.75 3.58579 12.4142 3.25 12 3.25C11.5858 3.25 11.25 3.58579 11.25 4L11.25 19.0309C11.1434 18.9655 11.0251 18.8813 10.8907 18.7734C10.4772 18.4413 9.98914 17.9505 9.28 17.235L7.53269 15.472C7.24111 15.1778 6.76624 15.1757 6.47204 15.4673C6.17784 15.7589 6.17573 16.2338 6.46731 16.528L8.2461 18.3227C8.91604 18.9987 9.46359 19.5511 9.95146 19.9429C10.4588 20.3504 10.9737 20.6453 11.5918 20.7241C11.8629 20.7586 12.1371 20.7586 12.4082 20.7241C13.0263 20.6453 13.5412 20.3504 14.0485 19.9429C14.5364 19.5511 15.0839 18.9987 15.7539 18.3227L17.5327 16.528C17.8243 16.2338 17.8222 15.7589 17.528 15.4673C17.2338 15.1757 16.7589 15.1778 16.4673 15.472L14.72 17.235C14.0109 17.9505 13.5228 18.4413 13.1093 18.7734C12.9749 18.8813 12.8566 18.9655 12.75 19.0309L12.75 4Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Up-1": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4082 3.27591C12.1371 3.24136 11.8629 3.24136 11.5918 3.27591C10.9737 3.35469 10.4588 3.64963 9.95146 4.05706C9.46359 4.44886 8.91604 5.00133 8.2461 5.67731L6.46731 7.47204C6.17573 7.76624 6.17784 8.24111 6.47204 8.53269C6.76624 8.82427 7.24111 8.82216 7.53269 8.52796L9.28 6.76499C9.98914 6.0495 10.4772 5.55869 10.8907 5.22659C11.0251 5.11867 11.1434 5.03446 11.25 4.96908L11.25 20C11.25 20.4142 11.5858 20.75 12 20.75C12.4142 20.75 12.75 20.4142 12.75 20L12.75 4.96908C12.8566 5.03446 12.9749 5.11867 13.1093 5.22659C13.5228 5.55869 14.0109 6.0495 14.72 6.76499L16.4673 8.52796C16.7589 8.82216 17.2338 8.82428 17.528 8.53269C17.8222 8.24111 17.8243 7.76624 17.5327 7.47204L15.7539 5.6773C15.084 5.00133 14.5364 4.44886 14.0485 4.05706C13.5412 3.64963 13.0263 3.3547 12.4082 3.27591Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Left-1": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.52796 7.53269C8.82216 7.24111 8.82428 6.76624 8.53269 6.47204C8.24111 6.17784 7.76624 6.17573 7.47204 6.46731L5.67731 8.2461C5.00134 8.91604 4.44886 9.46359 4.05706 9.95146C3.64963 10.4588 3.35469 10.9737 3.27591 11.5918C3.24136 11.8629 3.24136 12.1371 3.27591 12.4082C3.35469 13.0263 3.64963 13.5412 4.05706 14.0485C4.44886 14.5364 5.00134 15.084 5.67732 15.7539L7.47204 17.5327C7.76624 17.8243 8.24111 17.8222 8.53269 17.528C8.82427 17.2338 8.82216 16.7589 8.52796 16.4673L6.76499 14.72C6.0495 14.0109 5.55869 13.5228 5.22659 13.1093C5.11867 12.9749 5.03446 12.8566 4.96908 12.75H20C20.4142 12.75 20.75 12.4142 20.75 12C20.75 11.5858 20.4142 11.25 20 11.25H4.96908C5.03446 11.1434 5.11867 11.0251 5.22659 10.8907C5.55869 10.4772 6.0495 9.98914 6.76499 9.28L8.52796 7.53269Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Right-1": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.528 6.46731C16.2338 6.17573 15.7589 6.17784 15.4673 6.47204C15.1757 6.76624 15.1778 7.24111 15.472 7.53269L17.235 9.28C17.9505 9.98914 18.4413 10.4772 18.7734 10.8907C18.8813 11.0251 18.9655 11.1434 19.0309 11.25L4 11.25C3.58579 11.25 3.25 11.5858 3.25 12C3.25 12.4142 3.58579 12.75 4 12.75L19.0309 12.75C18.9655 12.8566 18.8813 12.9749 18.7734 13.1093C18.4413 13.5228 17.9505 14.0109 17.235 14.72L15.472 16.4673C15.1778 16.7589 15.1757 17.2338 15.4673 17.528C15.7589 17.8222 16.2338 17.8243 16.528 17.5327L18.3227 15.7539C18.9987 15.084 19.5511 14.5364 19.9429 14.0485C20.3504 13.5412 20.6453 13.0263 20.7241 12.4082C20.7414 12.2726 20.75 12.1363 20.75 12C20.75 11.8637 20.7414 11.7274 20.7241 11.5918C20.6453 10.9737 20.3504 10.4588 19.9429 9.95146C19.5511 9.46358 18.9987 8.91604 18.3227 8.24609L16.528 6.46731Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Down-2": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5327 10.528C17.8243 10.2338 17.8222 9.75889 17.528 9.46731C17.2338 9.17573 16.7589 9.17784 16.4673 9.47204L14.72 11.235C14.0109 11.9505 13.5228 12.4413 13.1093 12.7734C12.7076 13.096 12.4496 13.2067 12.2185 13.2361C12.0734 13.2546 11.9266 13.2546 11.7815 13.2361C11.5504 13.2067 11.2924 13.096 10.8907 12.7734C10.4772 12.4413 9.98914 11.9505 9.28 11.235L7.53269 9.47204C7.24111 9.17784 6.76624 9.17573 6.47204 9.46731C6.17784 9.75889 6.17573 10.2338 6.46731 10.528L8.24609 12.3227C8.91604 12.9987 9.46359 13.5511 9.95146 13.9429C10.4588 14.3504 10.9737 14.6453 11.5918 14.7241C11.8629 14.7586 12.1371 14.7586 12.4082 14.7241C13.0263 14.6453 13.5412 14.3504 14.0485 13.9429C14.5364 13.5511 15.084 12.9987 15.7539 12.3227L17.5327 10.528Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Up-2": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7815 10.7639C11.9266 10.7454 12.0734 10.7454 12.2185 10.7639C12.4496 10.7933 12.7076 10.904 13.1093 11.2266C13.5228 11.5587 14.0109 12.0495 14.72 12.765L16.4673 14.528C16.7589 14.8222 17.2338 14.8243 17.528 14.5327C17.8222 14.2411 17.8243 13.7662 17.5327 13.472L15.7539 11.6773C15.084 11.0013 14.5364 10.4489 14.0485 10.0571C13.5412 9.64963 13.0263 9.35469 12.4082 9.27591C12.1371 9.24136 11.8629 9.24136 11.5918 9.27591C10.9737 9.35469 10.4588 9.64963 9.95146 10.0571C9.46358 10.4489 8.91604 11.0013 8.24609 11.6773L6.46731 13.472C6.17573 13.7662 6.17784 14.2411 6.47204 14.5327C6.76624 14.8243 7.24111 14.8222 7.53269 14.528L9.28 12.765C9.98914 12.0495 10.4772 11.5587 10.8907 11.2266C11.2924 10.904 11.5504 10.7933 11.7815 10.7639Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Left-2": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.528 7.53269C14.8222 7.24111 14.8243 6.76624 14.5327 6.47204C14.2411 6.17784 13.7662 6.17573 13.472 6.46731L11.6773 8.2461C11.0013 8.91604 10.4489 9.46359 10.0571 9.95146C9.64963 10.4588 9.35469 10.9737 9.27591 11.5918C9.24136 11.8629 9.24136 12.1371 9.27591 12.4082C9.35469 13.0263 9.64963 13.5412 10.0571 14.0485C10.4489 14.5364 11.0013 15.084 11.6773 15.7539L13.472 17.5327C13.7662 17.8243 14.2411 17.8222 14.5327 17.528C14.8243 17.2338 14.8222 16.7589 14.528 16.4673L12.765 14.72C12.0495 14.0109 11.5587 13.5228 11.2266 13.1093C10.904 12.7076 10.7933 12.4496 10.7639 12.2185C10.7454 12.0734 10.7454 11.9266 10.7639 11.7815C10.7933 11.5504 10.904 11.2924 11.2266 10.8907C11.5587 10.4772 12.0495 9.98914 12.765 9.28L14.528 7.53269Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Right-2": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.528 6.46731C10.2338 6.17573 9.75889 6.17784 9.46731 6.47204C9.17573 6.76624 9.17784 7.24111 9.47204 7.53269L11.235 9.28C11.9505 9.98914 12.4413 10.4772 12.7734 10.8907C13.096 11.2924 13.2067 11.5504 13.2361 11.7815C13.2546 11.9266 13.2546 12.0734 13.2361 12.2185C13.2067 12.4496 13.096 12.7076 12.7734 13.1093C12.4413 13.5228 11.9505 14.0109 11.235 14.72L9.47204 16.4673C9.17784 16.7589 9.17573 17.2338 9.46731 17.528C9.75889 17.8222 10.2338 17.8243 10.528 17.5327L12.3227 15.7539C12.9987 15.084 13.5511 14.5364 13.9429 14.0485C14.3504 13.5412 14.6453 13.0263 14.7241 12.4082C14.7586 12.1371 14.7586 11.8629 14.7241 11.5918C14.6453 10.9737 14.3504 10.4588 13.9429 9.95146C13.5511 9.46359 12.9987 8.91604 12.3227 8.24609L10.528 6.46731Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Down-3": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7496 3C12.7496 2.58579 12.4138 2.25 11.9996 2.25C11.5854 2.25 11.2496 2.58579 11.2496 3L11.2496 13.25H9.13495C6.85289 13.25 5.56379 15.8692 6.95583 17.6775L9.82048 21.3987C10.9213 22.8288 13.0779 22.8288 14.1787 21.3988L17.0434 17.6775C18.4354 15.8692 17.1463 13.25 14.8642 13.25H12.7496L12.7496 3ZM8.14444 16.7625C7.51169 15.9405 8.09765 14.75 9.13495 14.75H11.9946L11.9996 14.75L12.0046 14.75H14.8642C15.9015 14.75 16.4875 15.9405 15.8547 16.7625L12.9901 20.4838C12.4897 21.1338 11.5095 21.1338 11.0091 20.4838L8.14444 16.7625Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Up-3": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1787 3.60111C13.0779 2.17109 10.9213 2.17109 9.82048 3.60111L6.95583 7.32239C5.56379 9.1307 6.85289 11.7499 9.13495 11.7499L11.2496 11.7499L11.2496 21.9999C11.2496 22.4141 11.5854 22.7499 11.9996 22.7499C12.4138 22.7499 12.7496 22.4141 12.7496 21.9999L12.7496 11.7499H14.8642C17.1463 11.7499 18.4354 9.13071 17.0434 7.32239L14.1787 3.60111ZM11.0091 4.5161C11.5095 3.86609 12.4897 3.86609 12.9901 4.5161L15.8547 8.23738C16.4875 9.05934 15.9015 10.2499 14.8642 10.2499L9.13495 10.2499C8.09765 10.2499 7.51169 9.05934 8.14444 8.23738L11.0091 4.5161Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Left-3": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.32212 6.95626C9.13043 5.56422 11.7496 6.85332 11.7496 9.13538V11.25L21.9996 11.25C22.4138 11.25 22.7496 11.5858 22.7496 12C22.7496 12.4142 22.4138 12.75 21.9996 12.75L11.7496 12.75V14.8647C11.7496 17.1467 9.13043 18.4358 7.32212 17.0438L3.60084 14.1791C2.17081 13.0783 2.17081 10.9217 3.60084 9.8209L7.32212 6.95626ZM8.23711 8.14487C9.05907 7.51212 10.2496 8.09808 10.2496 9.13538L10.2496 12L10.2496 14.8647C10.2496 15.902 9.05907 16.4879 8.23711 15.8552L4.51583 12.9905C3.86582 12.4901 3.86582 11.5099 4.51583 11.0095L8.23711 8.14487Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Right-3": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6775 17.0438C15.8692 18.4359 13.25 17.1467 13.25 14.8647V12.75L3 12.75C2.58579 12.75 2.25 12.4143 2.25 12C2.25 11.5858 2.58579 11.25 3 11.25L13.25 11.25V9.1354C13.25 6.85335 15.8692 5.56425 17.6775 6.95629L21.3988 9.82093C22.8288 10.9218 22.8288 13.0783 21.3988 14.1792L17.6775 17.0438ZM14.75 9.1354C14.75 8.09811 15.9405 7.51215 16.7625 8.1449L20.4838 11.0095C21.1338 11.5099 21.1338 12.4902 20.4838 12.9906L16.7625 15.8552C15.9405 16.4879 14.75 15.902 14.75 14.8647L14.75 12L14.75 9.1354Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  Login: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 3.75001C15.9577 3.75001 16.3492 3.75234 16.6648 3.80233C18.4834 4.09036 19.9096 5.51662 20.1977 7.33516C20.2477 7.65083 20.25 8.04234 20.25 9.00001V15C20.25 15.9577 20.2477 16.3492 20.1977 16.6649C19.9096 18.4834 18.4834 19.9097 16.6648 20.1977C16.3492 20.2477 15.9577 20.25 15 20.25H14C13.0424 20.25 12.1607 19.9342 11.4504 19.4007C11.1192 19.1519 10.6491 19.2188 10.4003 19.5499C10.1516 19.8811 10.2184 20.3513 10.5496 20.6C11.5106 21.3219 12.7064 21.75 14 21.75L15.0809 21.75C15.9329 21.7501 16.4518 21.7501 16.8995 21.6792C19.3599 21.2895 21.2895 19.3599 21.6792 16.8995C21.7501 16.4518 21.7501 15.9328 21.75 15.0808V8.91921C21.7501 8.06718 21.7501 7.54819 21.6792 7.10051C21.2895 4.64013 19.3599 2.71049 16.8995 2.3208C16.4518 2.2499 15.9329 2.24994 15.0809 2.25L14 2.25001C12.7064 2.25001 11.5106 2.67807 10.5496 3.39997C10.2184 3.64874 10.1516 4.11889 10.4003 4.45008C10.6491 4.78127 11.1192 4.84808 11.4504 4.59932C12.1607 4.06583 13.0424 3.75001 14 3.75001H15Z"
        fill="#3F3F3F"
      />
      <path
        d="M13.466 7.41233C13.1414 7.15498 12.6697 7.20947 12.4123 7.53404C12.155 7.85861 12.2095 8.33034 12.534 8.58769L14.297 9.98554C15.001 10.5438 15.483 10.9274 15.8105 11.25L3 11.25C2.58579 11.25 2.25 11.5858 2.25 12C2.25 12.4142 2.58579 12.75 3 12.75L15.8105 12.75C15.483 13.0727 15.001 13.4563 14.297 14.0145L12.534 15.4123C12.2095 15.6697 12.155 16.1414 12.4123 16.466C12.6697 16.7905 13.1414 16.845 13.466 16.5877L15.2648 15.1614C15.9373 14.6283 16.4922 14.1883 16.8875 13.7945C17.2932 13.3904 17.6294 12.9419 17.7208 12.3687C17.7402 12.2466 17.75 12.1234 17.75 12C17.75 11.8766 17.7402 11.7534 17.7208 11.6313C17.6294 11.0582 17.2932 10.6096 16.8875 10.2055C16.4922 9.81173 15.9373 9.37176 15.2648 8.83858L13.466 7.41233Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  Logout: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.91919 2.25C8.06717 2.24994 7.54819 2.2499 7.10051 2.3208C4.64013 2.71049 2.71049 4.64013 2.3208 7.10051C2.2499 7.54819 2.24994 8.06717 2.25 8.91919L2.25 15.0808C2.24994 15.9328 2.2499 16.4518 2.3208 16.8995C2.71049 19.3599 4.64013 21.2895 7.10051 21.6792C7.54819 21.7501 8.06718 21.7501 8.91921 21.75L10 21.75C11.2936 21.75 12.4894 21.3219 13.4504 20.6C13.7816 20.3513 13.8485 19.8811 13.5997 19.5499C13.3509 19.2188 12.8808 19.1519 12.5496 19.4007C11.8393 19.9342 10.9576 20.25 10 20.25H9.00001C8.04234 20.25 7.65083 20.2477 7.33516 20.1977C5.51662 19.9097 4.09036 18.4834 3.80233 16.6649C3.75234 16.3492 3.75001 15.9577 3.75001 15L3.75001 9.00001C3.75001 8.04234 3.75234 7.65083 3.80233 7.33516C4.09036 5.51662 5.51662 4.09036 7.33516 3.80233C7.65083 3.75234 8.04234 3.75001 9.00001 3.75001H10C10.9576 3.75001 11.8393 4.06583 12.5496 4.59932C12.8808 4.84808 13.3509 4.78127 13.5997 4.45008C13.8485 4.11888 13.7816 3.64874 13.4504 3.39997C12.4894 2.67807 11.2936 2.25001 10 2.25001L8.91919 2.25Z"
        fill="#3F3F3F"
      />
      <path
        d="M17.466 7.41232C17.1414 7.15498 16.6697 7.20947 16.4123 7.53404C16.155 7.85861 16.2095 8.33034 16.534 8.58769L18.297 9.98554C19.001 10.5438 19.483 10.9274 19.8105 11.25L7.00001 11.25C6.58579 11.25 6.25001 11.5858 6.25001 12C6.25001 12.4142 6.58579 12.75 7.00001 12.75L19.8105 12.75C19.483 13.0727 19.001 13.4563 18.297 14.0145L16.534 15.4123C16.2095 15.6697 16.155 16.1414 16.4123 16.466C16.6697 16.7905 17.1414 16.845 17.466 16.5877L19.2648 15.1615C19.9372 14.6283 20.4922 14.1883 20.8875 13.7945C21.2932 13.3904 21.6295 12.9419 21.7208 12.3687C21.7402 12.2466 21.75 12.1234 21.75 12C21.75 11.8766 21.7402 11.7534 21.7208 11.6313C21.6295 11.0582 21.2932 10.6096 20.8875 10.2055C20.4922 9.81173 19.9373 9.37176 19.2648 8.83858L17.466 7.41232Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Rotate-Right": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4069 0.996654C21.405 0.582445 21.0677 0.24816 20.6535 0.250008C20.2393 0.251856 19.905 0.589137 19.9069 1.00335L19.9157 2.98906C19.9192 3.75756 19.9205 4.28651 19.8822 4.69009C19.4451 4.21896 18.9662 3.78711 18.4515 3.40038C16.6546 2.05032 14.4197 1.25 12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 11.5858 22.4142 11.25 22 11.25C21.5858 11.25 21.25 11.5858 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C14.0836 2.75 16.0044 3.43804 17.5505 4.59962C18.0457 4.97173 18.5025 5.39242 18.9136 5.85457C18.8646 5.86231 18.8114 5.86938 18.7535 5.8757C18.3422 5.92062 17.7985 5.91933 16.9891 5.91572L15.0034 5.90686C14.5891 5.90501 14.2519 6.2393 14.25 6.65351C14.2482 7.06772 14.5824 7.405 14.9967 7.40685L17.0257 7.4159C17.7806 7.41929 18.4102 7.42211 18.9164 7.36684C19.4473 7.30886 19.9366 7.17926 20.3617 6.85029C20.4531 6.77952 20.5399 6.7031 20.6215 6.6215C20.7031 6.5399 20.7795 6.45314 20.8503 6.36169C21.1793 5.93661 21.3089 5.44728 21.3668 4.91635C21.4221 4.41017 21.4193 3.7806 21.4159 3.02573L21.4069 0.996654Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Rotate-Left": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.01269 1.00335C4.01454 0.589137 3.68025 0.251856 3.26604 0.250008C2.85183 0.24816 2.51455 0.582445 2.5127 0.996654L2.50365 3.02568C2.50026 3.78054 2.49744 4.41017 2.55271 4.91635C2.61069 5.44728 2.74029 5.93661 3.06926 6.36169C3.15834 6.4768 3.25638 6.58449 3.36242 6.68379C3.42487 6.74227 3.49008 6.79784 3.55786 6.85029C3.98294 7.17926 4.47227 7.30886 5.0032 7.36684C5.50939 7.42211 6.13896 7.41929 6.89383 7.4159H6.89384L8.9229 7.40685C9.3371 7.405 9.67139 7.06772 9.66954 6.65351C9.66769 6.2393 9.33041 5.90501 8.9162 5.90686L6.93049 5.91572C6.12107 5.91933 5.57738 5.92062 5.16604 5.8757C5.13511 5.87232 5.10551 5.86873 5.07717 5.86494C5.49067 5.39871 5.95055 4.97451 6.44953 4.59962C7.99559 3.43804 9.91636 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12C2.75 11.5858 2.41421 11.25 2 11.25C1.58579 11.25 1.25 11.5858 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C9.58034 1.25 7.34544 2.05032 5.54852 3.40038C5.00526 3.80854 4.50198 4.26696 4.04555 4.76877L4.04385 4.75351C3.99893 4.34217 4.00022 3.79848 4.00383 2.98906L4.01269 1.00335Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  Refresh: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.01269 1.00335C4.01454 0.589137 3.68025 0.251856 3.26604 0.250008C2.85183 0.24816 2.51455 0.582445 2.5127 0.996654L2.50365 3.02568C2.50026 3.78057 2.49743 4.41015 2.55271 4.91635C2.61069 5.44728 2.74029 5.93661 3.06926 6.36169C3.14048 6.45372 3.21743 6.54101 3.29962 6.62306C3.38076 6.70408 3.467 6.77998 3.55786 6.85029C3.98294 7.17926 4.47227 7.30886 5.0032 7.36684C5.50939 7.42211 6.13897 7.41929 6.89383 7.4159L8.9229 7.40685C9.3371 7.405 9.67139 7.06772 9.66954 6.65351C9.66769 6.2393 9.33041 5.90501 8.9162 5.90686L6.93049 5.91572C6.12107 5.91933 5.57738 5.92062 5.16604 5.8757C5.13511 5.87232 5.10551 5.86873 5.07717 5.86494C6.77277 3.9531 9.2455 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 12.4142 21.5858 12.75 22 12.75C22.4142 12.75 22.75 12.4142 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C8.84724 1.25 6.01093 2.60807 4.04555 4.76878L4.04385 4.75351C3.99893 4.34217 4.00022 3.79848 4.00383 2.98906L4.01269 1.00335Z"
        fill="#3F3F3F"
      />
      <path
        d="M2.75 12C2.75 11.5858 2.41421 11.25 2 11.25C1.58579 11.25 1.25 11.5858 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C15.1177 22.75 17.926 21.4219 19.8886 19.303C19.9203 19.6969 19.919 20.2078 19.9157 20.9305L19.9069 22.9162C19.905 23.3305 20.2393 23.6677 20.6535 23.6696C21.0677 23.6714 21.405 23.3371 21.4069 22.9229L21.4159 20.8939C21.4193 20.139 21.4221 19.5094 21.3668 19.0032C21.3089 18.4723 21.1793 17.983 20.8503 17.5579C20.7885 17.4781 20.7225 17.4019 20.6524 17.3295C20.5619 17.2361 20.4648 17.1491 20.3617 17.0693C19.9366 16.7403 19.4473 16.6107 18.9164 16.5528C18.4102 16.4975 17.7806 16.5003 17.0257 16.5037L14.9967 16.5127C14.5824 16.5146 14.2482 16.8519 14.25 17.2661C14.2519 17.6803 14.5891 18.0146 15.0034 18.0127L16.9891 18.0039C17.7985 18.0003 18.3422 17.999 18.7535 18.0439C18.8365 18.053 18.91 18.0636 18.9752 18.0755C17.2784 20.0219 14.783 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Refresh-2": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.406 0.996654C21.4042 0.582445 21.0669 0.24816 20.6527 0.250008C20.2385 0.251856 19.9042 0.589137 19.906 1.00335L19.9149 2.98906C19.9183 3.75704 19.9196 4.28579 19.8814 4.68926C17.9191 2.57481 15.1139 1.25 12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 12.4142 1.58579 12.75 2 12.75C2.41421 12.75 2.75 12.4142 2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C14.7495 2.75 17.2182 3.9487 18.9135 5.85445C18.8643 5.86224 18.8108 5.86935 18.7527 5.8757C18.3413 5.92062 17.7976 5.91933 16.9882 5.91572L15.0025 5.90686C14.5883 5.90501 14.251 6.2393 14.2492 6.65351C14.2473 7.06772 14.5816 7.405 14.9958 7.40685L17.0248 7.4159C17.7797 7.41929 18.4093 7.42211 18.9155 7.36684C19.4464 7.30886 19.9358 7.17926 20.3608 6.85029C20.5437 6.70875 20.7079 6.54459 20.8494 6.36169C21.1784 5.93661 21.308 5.44728 21.366 4.91635C21.4213 4.41017 21.4184 3.7806 21.4151 3.02573L21.406 0.996654Z"
        fill="#3F3F3F"
      />
      <path
        d="M22.75 12C22.75 11.5858 22.4142 11.25 22 11.25C21.5858 11.25 21.25 11.5858 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C9.21132 21.25 6.71144 20.0169 5.0144 18.0636C5.06084 18.0564 5.11096 18.0498 5.16519 18.0439C5.57653 17.999 6.12022 18.0003 6.92964 18.0039L8.91535 18.0127C9.32956 18.0146 9.66684 17.6803 9.66869 17.2661C9.67054 16.8519 9.33625 16.5146 8.92204 16.5127L6.89302 16.5037C6.13816 16.5003 5.50853 16.4975 5.00235 16.5528C4.47142 16.6107 3.98209 16.7403 3.55701 17.0693C3.37411 17.2108 3.20995 17.375 3.06841 17.5579C2.73944 17.983 2.60984 18.4723 2.55186 19.0032C2.49658 19.5094 2.49941 20.139 2.5028 20.8939L2.51185 22.9229C2.5137 23.3371 2.85098 23.6714 3.26519 23.6696C3.6794 23.6677 4.01368 23.3305 4.01184 22.9162L4.00298 20.9305C3.99953 20.1573 3.9982 19.6266 4.03724 19.2221C6.00294 21.3881 8.8428 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  Undo: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.72796 5.53269C8.02216 5.24111 8.02427 4.76624 7.73269 4.47204C7.44111 4.17784 6.96624 4.17573 6.67204 4.46731L5.2309 5.89565C4.69472 6.42704 4.24754 6.87022 3.92869 7.26724C3.59427 7.68366 3.33989 8.12131 3.27193 8.6545C3.25731 8.76921 3.25 8.8846 3.25 9C3.25 9.1154 3.25731 9.23079 3.27193 9.34549C3.33989 9.87869 3.59427 10.3163 3.92869 10.7328C4.24753 11.1298 4.69472 11.573 5.23089 12.1043L6.67204 13.5327C6.96624 13.8243 7.44111 13.8222 7.73269 13.528C8.02427 13.2338 8.02216 12.7589 7.72796 12.4673L6.31758 11.0695C5.74268 10.4997 5.35733 10.1161 5.09823 9.79351C5.08635 9.77872 5.07487 9.76422 5.06379 9.75H14C16.3473 9.75 18.25 11.6528 18.25 14C18.25 16.3472 16.3473 18.25 14 18.25H6.00005C5.58584 18.25 5.25005 18.5858 5.25005 19C5.25005 19.4142 5.58584 19.75 6.00005 19.75H14C17.1757 19.75 19.75 17.1756 19.75 14C19.75 10.8244 17.1757 8.25 14 8.25H5.06379C5.07487 8.23579 5.08635 8.22128 5.09823 8.20649C5.35733 7.88386 5.74268 7.50033 6.31758 6.93054L7.72796 5.53269Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  Redo: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.942 4.46731C16.6478 4.17573 16.173 4.17784 15.8814 4.47204C15.5898 4.76624 15.5919 5.24111 15.8861 5.53269L17.2965 6.93054C17.8714 7.50033 18.2567 7.88386 18.5158 8.20649C18.5188 8.21019 18.5217 8.21387 18.5247 8.21753C18.5334 8.22852 18.542 8.23934 18.5503 8.25H10C6.82436 8.25 4.25 10.8244 4.25 14C4.25 17.1756 6.82436 19.75 10 19.75H18C18.4142 19.75 18.75 19.4142 18.75 19C18.75 18.5858 18.4142 18.25 18 18.25H10C7.65279 18.25 5.75 16.3472 5.75 14C5.75 11.6528 7.65279 9.75 10 9.75H18.5503C18.5392 9.76422 18.5277 9.77872 18.5158 9.79351C18.2567 10.1161 17.8714 10.4997 17.2965 11.0695L15.8861 12.4673C15.5919 12.7589 15.5898 13.2338 15.8814 13.528C16.173 13.8222 16.6478 13.8243 16.942 13.5327L18.3831 12.1044C18.9193 11.573 19.3665 11.1298 19.6854 10.7328C20.0198 10.3163 20.2742 9.87869 20.3421 9.3455C20.3567 9.23079 20.3641 9.1154 20.3641 9C20.3641 8.88461 20.3568 8.76921 20.3421 8.65451C20.2742 8.12131 20.0198 7.68366 19.6854 7.26724C19.3665 6.87022 18.9193 6.42704 18.3832 5.89566L16.942 4.46731Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Arrow-Swap-Horizontal": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.72786 4.53269C9.02206 4.24111 9.02418 3.76624 8.73259 3.47204C8.44101 3.17784 7.96614 3.17573 7.67194 3.46731L6.2308 4.89565C5.69462 5.42704 5.24744 5.87022 4.92859 6.26724C4.59417 6.68367 4.33979 7.12131 4.27183 7.6545C4.1889 8.30512 4.72846 8.75 5.25133 8.75H19C19.4142 8.75 19.75 8.41421 19.75 8C19.75 7.58579 19.4142 7.25 19 7.25H6.06369C6.07477 7.23578 6.08625 7.22128 6.09813 7.20649C6.35723 6.88386 6.74258 6.50033 7.31748 5.93054L8.72786 4.53269Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 15.25C4.58579 15.25 4.25 15.5858 4.25 16C4.25 16.4142 4.58579 16.75 5 16.75H18.1366C18.1255 16.7642 18.114 16.7787 18.1021 16.7935C17.843 17.1161 17.4577 17.4997 16.8828 18.0695L15.4724 19.4673C15.1782 19.7589 15.1761 20.2338 15.4677 20.528C15.7592 20.8222 16.2341 20.8243 16.5283 20.5327L17.9694 19.1044C18.5056 18.573 18.9528 18.1298 19.2717 17.7328C19.6061 17.3163 19.8604 16.8787 19.9284 16.3455C19.9314 16.3222 19.934 16.299 19.9364 16.2757C19.9975 15.6728 19.502 15.25 19 15.25H5Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Arrow-Swap-Vertical": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2757 4.06357C15.6728 4.00244 15.25 4.49798 15.25 4.99997V19C15.25 19.4142 15.5858 19.75 16 19.75C16.4142 19.75 16.75 19.4142 16.75 19L16.75 5.86342C16.7642 5.8745 16.7787 5.88598 16.7935 5.89786C17.1161 6.15696 17.4997 6.54231 18.0695 7.11721L19.4673 8.52759C19.7589 8.82179 20.2338 8.8239 20.528 8.53232C20.8222 8.24074 20.8243 7.76587 20.5327 7.47167L19.1044 6.03054C18.573 5.49435 18.1298 5.04717 17.7328 4.72832C17.3163 4.3939 16.8787 4.13952 16.3455 4.07156C16.3222 4.0686 16.299 4.06593 16.2757 4.06357Z"
        fill="#3F3F3F"
      />
      <path
        d="M8.75 4.99997C8.75 4.58576 8.41421 4.24997 8 4.24997C7.58579 4.24997 7.25 4.58576 7.25 4.99997L7.25 17.9363C7.23578 17.9252 7.22128 17.9137 7.20649 17.9018C6.88386 17.6427 6.50033 17.2574 5.93054 16.6825L4.53269 15.2721C4.24111 14.9779 3.76624 14.9758 3.47204 15.2674C3.17784 15.559 3.17573 16.0338 3.46731 16.328L4.89563 17.7692C5.42702 18.3053 5.87022 18.7525 6.26724 19.0714C6.68367 19.4058 7.12131 19.6602 7.6545 19.7281C8.30512 19.8111 8.75 19.2715 8.75 18.7486L8.75 4.99997Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  Foreign: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.971 1.75208C18.9784 1.74759 19.6705 1.74564 20.1977 1.80321C20.3691 1.82193 20.5123 1.84604 20.6339 1.87518L10.0054 12.5037C9.71253 12.7965 9.71253 13.2714 10.0054 13.5643C10.2983 13.8572 10.7732 13.8572 11.0661 13.5643L21.6946 2.93585C21.7237 3.05745 21.7478 3.20065 21.7665 3.37199C21.8241 3.89923 21.8221 4.59138 21.8177 5.59875L21.8066 8.08089C21.8047 8.4951 22.139 8.83238 22.5532 8.83423C22.9674 8.83607 23.3047 8.50179 23.3066 8.08758L23.3178 5.56074C23.3221 4.60902 23.3256 3.83119 23.2577 3.20915C23.187 2.56233 23.0314 1.98966 22.6501 1.49688C22.5675 1.3902 22.4785 1.28892 22.3834 1.19358C22.286 1.09581 22.1822 1.0043 22.0729 0.919649C21.5801 0.538287 21.0074 0.382717 20.3606 0.31208C19.7386 0.244151 18.9607 0.247636 18.009 0.251899L15.4822 0.263173C15.0679 0.265021 14.7337 0.602302 14.7355 1.01651C14.7374 1.43072 15.0746 1.76501 15.4888 1.76316L17.971 1.75208Z"
        fill="#3F3F3F"
      />
      <path
        d="M13.009 3.44199C12.4034 3.43473 11.7363 3.43473 11.0032 3.43473H10.9547C9.11821 3.43473 7.67861 3.43472 6.53648 3.55846C5.37094 3.68474 4.42656 3.94706 3.62024 4.53289C3.13209 4.88754 2.70281 5.31682 2.34815 5.80497C1.76232 6.61129 1.50001 7.55567 1.37373 8.72121C1.24999 9.86335 1.24999 11.3029 1.25 13.1395V13.23C1.24999 15.0665 1.24999 16.5061 1.37373 17.6483C1.50001 18.8138 1.76232 19.7582 2.34815 20.5645C2.70281 21.0526 3.13209 21.4819 3.62024 21.8366C4.42656 22.4224 5.37094 22.6847 6.53648 22.811C7.67859 22.9347 9.11817 22.9347 10.9547 22.9347H11.0453C12.8818 22.9347 14.3214 22.9347 15.4635 22.811C16.6291 22.6847 17.5734 22.4224 18.3798 21.8366C18.8679 21.4819 19.2972 21.0526 19.6518 20.5645C20.2377 19.7582 20.5 18.8138 20.6263 17.6483C20.75 16.5061 20.75 15.0666 20.75 13.2301V13.1813C20.75 12.4483 20.75 11.7813 20.7427 11.1757C20.7378 10.7616 20.398 10.4298 19.9838 10.4348C19.5696 10.4398 19.2379 10.7795 19.2428 11.1937C19.25 11.7895 19.25 12.448 19.25 13.1847C19.25 15.0763 19.249 16.4347 19.135 17.4867C19.0225 18.5246 18.8074 19.1748 18.4383 19.6828C18.1762 20.0436 17.8589 20.3609 17.4981 20.6231C16.9901 20.9921 16.3399 21.2073 15.302 21.3197C14.25 21.4337 12.8916 21.4347 11 21.4347C9.10843 21.4347 7.74999 21.4337 6.69804 21.3197C5.66013 21.2073 5.00992 20.9921 4.50191 20.6231C4.14111 20.3609 3.82382 20.0436 3.56168 19.6828C3.19259 19.1748 2.97745 18.5246 2.865 17.4867C2.75103 16.4347 2.75 15.0763 2.75 13.1847C2.75 11.2932 2.75103 9.93473 2.865 8.88278C2.97745 7.84487 3.19259 7.19465 3.56168 6.68665C3.82382 6.32584 4.14111 6.00855 4.50191 5.74641C5.00992 5.37733 5.66013 5.16219 6.69804 5.04974C7.74999 4.93576 9.10843 4.93473 11 4.93473C11.7367 4.93473 12.3952 4.93474 12.991 4.94189C13.4052 4.94685 13.745 4.61511 13.7499 4.20093C13.7549 3.78675 13.4232 3.44696 13.009 3.44199Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
};

export type ArrowsIconsType = keyof typeof ArrowsIcons;
