export const EditorIcons = {
  Italic: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 7C8.25 6.58579 8.58579 6.25 9 6.25H12.9845C12.9943 6.24981 13.0041 6.24981 13.0139 6.25H17C17.4142 6.25 17.75 6.58579 17.75 7C17.75 7.41421 17.4142 7.75 17 7.75H13.6149L11.9149 16.25H15C15.4142 16.25 15.75 16.5858 15.75 17C15.75 17.4142 15.4142 17.75 15 17.75H11.0155C11.0057 17.7502 10.9959 17.7502 10.9861 17.75H7C6.58579 17.75 6.25 17.4142 6.25 17C6.25 16.5858 6.58579 16.25 7 16.25H10.3851L12.0851 7.75H9C8.58579 7.75 8.25 7.41421 8.25 7Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4635 2.37373C15.3214 2.24999 13.8818 2.24999 12.0452 2.25H11.9548C10.1182 2.24999 8.67861 2.24999 7.53648 2.37373C6.37094 2.50001 5.42656 2.76232 4.62024 3.34815C4.13209 3.70281 3.70281 4.13209 3.34815 4.62024C2.76232 5.42656 2.50001 6.37094 2.37373 7.53648C2.24999 8.67861 2.24999 10.1182 2.25 11.9548V12.0452C2.24999 13.8818 2.24999 15.3214 2.37373 16.4635C2.50001 17.6291 2.76232 18.5734 3.34815 19.3798C3.70281 19.8679 4.13209 20.2972 4.62024 20.6518C5.42656 21.2377 6.37094 21.5 7.53648 21.6263C8.67859 21.75 10.1182 21.75 11.9547 21.75H12.0453C13.8818 21.75 15.3214 21.75 16.4635 21.6263C17.6291 21.5 18.5734 21.2377 19.3798 20.6518C19.8679 20.2972 20.2972 19.8679 20.6518 19.3798C21.2377 18.5734 21.5 17.6291 21.6263 16.4635C21.75 15.3214 21.75 13.8818 21.75 12.0453V11.9547C21.75 10.1182 21.75 8.67859 21.6263 7.53648C21.5 6.37094 21.2377 5.42656 20.6518 4.62024C20.2972 4.13209 19.8679 3.70281 19.3798 3.34815C18.5734 2.76232 17.6291 2.50001 16.4635 2.37373ZM5.50191 4.56168C6.00992 4.19259 6.66013 3.97745 7.69804 3.865C8.74999 3.75103 10.1084 3.75 12 3.75C13.8916 3.75 15.25 3.75103 16.302 3.865C17.3399 3.97745 17.9901 4.19259 18.4981 4.56168C18.8589 4.82382 19.1762 5.14111 19.4383 5.50191C19.8074 6.00992 20.0225 6.66013 20.135 7.69804C20.249 8.74999 20.25 10.1084 20.25 12C20.25 13.8916 20.249 15.25 20.135 16.302C20.0225 17.3399 19.8074 17.9901 19.4383 18.4981C19.1762 18.8589 18.8589 19.1762 18.4981 19.4383C17.9901 19.8074 17.3399 20.0225 16.302 20.135C15.25 20.249 13.8916 20.25 12 20.25C10.1084 20.25 8.74999 20.249 7.69804 20.135C6.66013 20.0225 6.00992 19.8074 5.50191 19.4383C5.14111 19.1762 4.82382 18.8589 4.56168 18.4981C4.19259 17.9901 3.97745 17.3399 3.865 16.302C3.75103 15.25 3.75 13.8916 3.75 12C3.75 10.1084 3.75103 8.74999 3.865 7.69804C3.97745 6.66013 4.19259 6.00992 4.56168 5.50191C4.82382 5.14111 5.14111 4.82382 5.50191 4.56168Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  Bold: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.073 12C15.6438 11.5001 16.0832 10.7038 16.0832 9.5C16.0832 7.97244 15.3757 7.1009 14.5898 6.65884C13.8668 6.25216 13.1567 6.25 13.1109 6.25H9.66651C8.70001 6.25 7.9165 7.0335 7.9165 8V16C7.9165 16.9665 8.70001 17.75 9.6665 17.75H13.1109V17C13.1109 17.75 13.1105 17.75 13.1109 17.75L13.1127 17.75L13.1147 17.75L13.1193 17.75L13.1309 17.7498C13.1398 17.7496 13.1509 17.7494 13.164 17.7489C13.1902 17.7479 13.2248 17.7461 13.2665 17.7427C13.3497 17.736 13.4629 17.7231 13.5964 17.6981C13.8603 17.6486 14.2214 17.5484 14.5898 17.3412C15.3756 16.8991 16.0832 16.0276 16.0832 14.5C16.0832 13.2962 15.6438 12.4999 15.073 12ZM9.66651 7.75C9.52843 7.75 9.4165 7.86193 9.4165 8V11.25L13.1054 11.25C13.106 11.25 13.1068 11.25 13.1078 11.2499C13.1141 11.2497 13.1273 11.2491 13.1464 11.2475C13.1847 11.2444 13.2451 11.2378 13.32 11.2238C13.4727 11.1951 13.6672 11.1391 13.8544 11.0338C14.1796 10.8509 14.5832 10.4724 14.5832 9.5C14.5832 8.5276 14.1796 8.14914 13.8544 7.96619C13.4866 7.75934 13.1074 7.75038 13.1074 7.75002C13.1074 7.75001 13.1069 7.75002 13.1074 7.75002L9.66651 7.75ZM13.1054 16.25C13.106 16.25 13.1068 16.25 13.1078 16.2499C13.1141 16.2497 13.1273 16.2491 13.1464 16.2475C13.1847 16.2444 13.2451 16.2378 13.32 16.2238C13.4727 16.1951 13.6672 16.1391 13.8544 16.0338C14.1796 15.8509 14.5832 15.4724 14.5832 14.5C14.5832 13.5276 14.1796 13.1491 13.8544 12.9662C13.6672 12.8609 13.4727 12.8049 13.32 12.7762C13.2451 12.7622 13.1847 12.7556 13.1464 12.7525C13.1273 12.7509 13.1141 12.7503 13.1078 12.7501C13.1068 12.75 13.106 12.75 13.1054 12.75L9.4165 12.75V16C9.4165 16.1381 9.52843 16.25 9.6665 16.25H13.1054Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9548 2.25H12.0452C13.8818 2.24999 15.3214 2.24999 16.4635 2.37373C17.6291 2.50001 18.5734 2.76232 19.3798 3.34815C19.8679 3.70281 20.2972 4.13209 20.6518 4.62024C21.2377 5.42656 21.5 6.37094 21.6263 7.53648C21.75 8.67861 21.75 10.1182 21.75 11.9547V12.0453C21.75 13.8818 21.75 15.3214 21.6263 16.4635C21.5 17.6291 21.2377 18.5734 20.6518 19.3798C20.2972 19.8679 19.8679 20.2972 19.3798 20.6518C18.5734 21.2377 17.6291 21.5 16.4635 21.6263C15.3214 21.75 13.8818 21.75 12.0453 21.75H11.9547C10.1182 21.75 8.67861 21.75 7.53648 21.6263C6.37094 21.5 5.42656 21.2377 4.62024 20.6518C4.13209 20.2972 3.70281 19.8679 3.34815 19.3798C2.76232 18.5734 2.50001 17.6291 2.37373 16.4635C2.24999 15.3214 2.24999 13.8818 2.25 12.0452V11.9548C2.24999 10.1182 2.24999 8.67861 2.37373 7.53648C2.50001 6.37094 2.76232 5.42656 3.34815 4.62024C3.70281 4.13209 4.13209 3.70281 4.62024 3.34815C5.42656 2.76232 6.37094 2.50001 7.53648 2.37373C8.67861 2.24999 10.1182 2.24999 11.9548 2.25ZM7.69805 3.865C6.66013 3.97745 6.00992 4.19259 5.50191 4.56168C5.14111 4.82382 4.82382 5.14111 4.56168 5.50191C4.19259 6.00992 3.97745 6.66013 3.865 7.69805C3.75103 8.74999 3.75 10.1084 3.75 12C3.75 13.8916 3.75103 15.25 3.865 16.302C3.97745 17.3399 4.19259 17.9901 4.56168 18.4981C4.82382 18.8589 5.14111 19.1762 5.50191 19.4383C6.00992 19.8074 6.66013 20.0225 7.69805 20.135C8.74999 20.249 10.1084 20.25 12 20.25C13.8916 20.25 15.25 20.249 16.302 20.135C17.3399 20.0225 17.9901 19.8074 18.4981 19.4383C18.8589 19.1762 19.1762 18.8589 19.4383 18.4981C19.8074 17.9901 20.0225 17.3399 20.135 16.302C20.249 15.25 20.25 13.8916 20.25 12C20.25 10.1084 20.249 8.74999 20.135 7.69805C20.0225 6.66013 19.8074 6.00992 19.4383 5.50191C19.1762 5.14111 18.8589 4.82382 18.4981 4.56168C17.9901 4.19259 17.3399 3.97745 16.302 3.865C15.25 3.75103 13.8916 3.75 12 3.75C10.1084 3.75 8.74999 3.75103 7.69805 3.865Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  Text: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 6.25C8.48122 6.25 7.25 7.48122 7.25 9V10C7.25 10.4142 7.58579 10.75 8 10.75C8.41421 10.75 8.75 10.4142 8.75 10V9C8.75 8.30964 9.30964 7.75 10 7.75H11.25V16.25H10C9.58579 16.25 9.25 16.5858 9.25 17C9.25 17.4142 9.58579 17.75 10 17.75H14C14.4142 17.75 14.75 17.4142 14.75 17C14.75 16.5858 14.4142 16.25 14 16.25H12.75V7.75H14C14.6904 7.75 15.25 8.30964 15.25 9V10C15.25 10.4142 15.5858 10.75 16 10.75C16.4142 10.75 16.75 10.4142 16.75 10V9C16.75 7.48122 15.5188 6.25 14 6.25H10Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4635 2.37373C15.3214 2.24999 13.8818 2.24999 12.0452 2.25H11.9548C10.1182 2.24999 8.67861 2.24999 7.53648 2.37373C6.37094 2.50001 5.42656 2.76232 4.62024 3.34815C4.13209 3.70281 3.70281 4.13209 3.34815 4.62024C2.76232 5.42656 2.50001 6.37094 2.37373 7.53648C2.24999 8.67861 2.24999 10.1182 2.25 11.9548V12.0452C2.24999 13.8818 2.24999 15.3214 2.37373 16.4635C2.50001 17.6291 2.76232 18.5734 3.34815 19.3798C3.70281 19.8679 4.13209 20.2972 4.62024 20.6518C5.42656 21.2377 6.37094 21.5 7.53648 21.6263C8.67859 21.75 10.1182 21.75 11.9547 21.75H12.0453C13.8818 21.75 15.3214 21.75 16.4635 21.6263C17.6291 21.5 18.5734 21.2377 19.3798 20.6518C19.8679 20.2972 20.2972 19.8679 20.6518 19.3798C21.2377 18.5734 21.5 17.6291 21.6263 16.4635C21.75 15.3214 21.75 13.8818 21.75 12.0453V11.9547C21.75 10.1182 21.75 8.67859 21.6263 7.53648C21.5 6.37094 21.2377 5.42656 20.6518 4.62024C20.2972 4.13209 19.8679 3.70281 19.3798 3.34815C18.5734 2.76232 17.6291 2.50001 16.4635 2.37373ZM5.50191 4.56168C6.00992 4.19259 6.66013 3.97745 7.69804 3.865C8.74999 3.75103 10.1084 3.75 12 3.75C13.8916 3.75 15.25 3.75103 16.302 3.865C17.3399 3.97745 17.9901 4.19259 18.4981 4.56168C18.8589 4.82382 19.1762 5.14111 19.4383 5.50191C19.8074 6.00992 20.0225 6.66013 20.135 7.69804C20.249 8.74999 20.25 10.1084 20.25 12C20.25 13.8916 20.249 15.25 20.135 16.302C20.0225 17.3399 19.8074 17.9901 19.4383 18.4981C19.1762 18.8589 18.8589 19.1762 18.4981 19.4383C17.9901 19.8074 17.3399 20.0225 16.302 20.135C15.25 20.249 13.8916 20.25 12 20.25C10.1084 20.25 8.74999 20.249 7.69804 20.135C6.66013 20.0225 6.00992 19.8074 5.50191 19.4383C5.14111 19.1762 4.82382 18.8589 4.56168 18.4981C4.19259 17.9901 3.97745 17.3399 3.865 16.302C3.75103 15.25 3.75 13.8916 3.75 12C3.75 10.1084 3.75103 8.74999 3.865 7.69804C3.97745 6.66013 4.19259 6.00992 4.56168 5.50191C4.82382 5.14111 5.14111 4.82382 5.50191 4.56168Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Text-Align-Left": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5.25C4.58579 5.25 4.25 5.58579 4.25 6C4.25 6.41421 4.58579 6.75 5 6.75H19C19.4142 6.75 19.75 6.41421 19.75 6C19.75 5.58579 19.4142 5.25 19 5.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 9.25C4.58579 9.25 4.25 9.58579 4.25 10C4.25 10.4142 4.58579 10.75 5 10.75H11C11.4142 10.75 11.75 10.4142 11.75 10C11.75 9.58579 11.4142 9.25 11 9.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 13.25C4.58579 13.25 4.25 13.5858 4.25 14C4.25 14.4142 4.58579 14.75 5 14.75H19C19.4142 14.75 19.75 14.4142 19.75 14C19.75 13.5858 19.4142 13.25 19 13.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 17.25C4.58579 17.25 4.25 17.5858 4.25 18C4.25 18.4142 4.58579 18.75 5 18.75H11C11.4142 18.75 11.75 18.4142 11.75 18C11.75 17.5858 11.4142 17.25 11 17.25H5Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Text-Align-Right": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 6.75L19 6.75C19.4142 6.75 19.75 6.41421 19.75 6C19.75 5.58579 19.4142 5.25 19 5.25H5C4.58579 5.25 4.25 5.58579 4.25 6C4.25 6.41421 4.58579 6.75 5 6.75Z"
        fill="#3F3F3F"
      />
      <path
        d="M13 10.75L19 10.75C19.4142 10.75 19.75 10.4142 19.75 10C19.75 9.58579 19.4142 9.25 19 9.25L13 9.25C12.5858 9.25 12.25 9.58579 12.25 10C12.25 10.4142 12.5858 10.75 13 10.75Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 13.25C4.58579 13.25 4.25 13.5858 4.25 14C4.25 14.4142 4.58579 14.75 5 14.75L19 14.75C19.4142 14.75 19.75 14.4142 19.75 14C19.75 13.5858 19.4142 13.25 19 13.25L5 13.25Z"
        fill="#3F3F3F"
      />
      <path
        d="M13 18.75L19 18.75C19.4142 18.75 19.75 18.4142 19.75 18C19.75 17.5858 19.4142 17.25 19 17.25L13 17.25C12.5858 17.25 12.25 17.5858 12.25 18C12.25 18.4142 12.5858 18.75 13 18.75Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Text-Align-Center": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5.25C4.58579 5.25 4.25 5.58579 4.25 6C4.25 6.41421 4.58579 6.75 5 6.75H19C19.4142 6.75 19.75 6.41421 19.75 6C19.75 5.58579 19.4142 5.25 19 5.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M9 9.25C8.58579 9.25 8.25 9.58579 8.25 10C8.25 10.4142 8.58579 10.75 9 10.75H15C15.4142 10.75 15.75 10.4142 15.75 10C15.75 9.58579 15.4142 9.25 15 9.25H9Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 13.25C4.58579 13.25 4.25 13.5858 4.25 14C4.25 14.4142 4.58579 14.75 5 14.75H19C19.4142 14.75 19.75 14.4142 19.75 14C19.75 13.5858 19.4142 13.25 19 13.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M9 17.25C8.58579 17.25 8.25 17.5858 8.25 18C8.25 18.4142 8.58579 18.75 9 18.75H15C15.4142 18.75 15.75 18.4142 15.75 18C15.75 17.5858 15.4142 17.25 15 17.25H9Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Text-Align-Justify-Center": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5.25C4.58579 5.25 4.25 5.58579 4.25 6C4.25 6.41421 4.58579 6.75 5 6.75H19C19.4142 6.75 19.75 6.41421 19.75 6C19.75 5.58579 19.4142 5.25 19 5.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 9.25C4.58579 9.25 4.25 9.58579 4.25 10C4.25 10.4142 4.58579 10.75 5 10.75H19C19.4142 10.75 19.75 10.4142 19.75 10C19.75 9.58579 19.4142 9.25 19 9.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 13.25C4.58579 13.25 4.25 13.5858 4.25 14C4.25 14.4142 4.58579 14.75 5 14.75H19C19.4142 14.75 19.75 14.4142 19.75 14C19.75 13.5858 19.4142 13.25 19 13.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 17.25C4.58579 17.25 4.25 17.5858 4.25 18C4.25 18.4142 4.58579 18.75 5 18.75H19C19.4142 18.75 19.75 18.4142 19.75 18C19.75 17.5858 19.4142 17.25 19 17.25H5Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Text-Align-Justify-Right": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 5.25C12.5858 5.25 12.25 5.58579 12.25 6C12.25 6.41421 12.5858 6.75 13 6.75H19C19.4142 6.75 19.75 6.41421 19.75 6C19.75 5.58579 19.4142 5.25 19 5.25H13Z"
        fill="#3F3F3F"
      />
      <path
        d="M13 9.25C12.5858 9.25 12.25 9.58579 12.25 10C12.25 10.4142 12.5858 10.75 13 10.75H19C19.4142 10.75 19.75 10.4142 19.75 10C19.75 9.58579 19.4142 9.25 19 9.25H13Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 13.25C4.58579 13.25 4.25 13.5858 4.25 14C4.25 14.4142 4.58579 14.75 5 14.75H19C19.4142 14.75 19.75 14.4142 19.75 14C19.75 13.5858 19.4142 13.25 19 13.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 17.25C4.58579 17.25 4.25 17.5858 4.25 18C4.25 18.4142 4.58579 18.75 5 18.75H19C19.4142 18.75 19.75 18.4142 19.75 18C19.75 17.5858 19.4142 17.25 19 17.25H5Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Text-Align-Justify-Left": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5.25C4.58579 5.25 4.25 5.58579 4.25 6C4.25 6.41421 4.58579 6.75 5 6.75H11C11.4142 6.75 11.75 6.41421 11.75 6C11.75 5.58579 11.4142 5.25 11 5.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 9.25C4.58579 9.25 4.25 9.58579 4.25 10C4.25 10.4142 4.58579 10.75 5 10.75H11C11.4142 10.75 11.75 10.4142 11.75 10C11.75 9.58579 11.4142 9.25 11 9.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 13.25C4.58579 13.25 4.25 13.5858 4.25 14C4.25 14.4142 4.58579 14.75 5 14.75H19C19.4142 14.75 19.75 14.4142 19.75 14C19.75 13.5858 19.4142 13.25 19 13.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 17.25C4.58579 17.25 4.25 17.5858 4.25 18C4.25 18.4142 4.58579 18.75 5 18.75H19C19.4142 18.75 19.75 18.4142 19.75 18C19.75 17.5858 19.4142 17.25 19 17.25H5Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
};

export type EditorIconsType = keyof typeof EditorIcons;
