import React from "react";
import { ArrowsIcons } from "./Arrows";
import { CallIcons } from "./Call";
import { EditorIcons } from "./Editor";
import { EssentialIcons } from "./Essential";
import { FilesIcons } from "./Files";
import { LikeIcons } from "./Like";
import { LocationIcons } from "./Location";
import { MenuIcons } from "./Menu";
import { MessagesIcons } from "./Messages";
import { MoneyIcons } from "./Money";
import { SecurityIcons } from "./Security";
import { ShopIcons } from "./Shop";
import { TimeIcons } from "./Time";
import { UsersIcons } from "./Users";

const icons = Object.assign(
  ArrowsIcons,
  CallIcons,
  EditorIcons,
  EssentialIcons,
  FilesIcons,
  LikeIcons,
  LocationIcons,
  MenuIcons,
  MessagesIcons,
  MoneyIcons,
  SecurityIcons,
  ShopIcons,
  TimeIcons,
  UsersIcons
);

export type IconsKeyType = keyof typeof icons;

export const getIcon = (name: IconsKeyType): React.FunctionComponent =>
  icons[name] as unknown as React.FunctionComponent;
