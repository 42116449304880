export const CallIcons = {
  Call: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.11183 2.97505C7.05974 2.00832 5.44259 2.00832 4.3905 2.97505C4.34741 3.01464 4.30142 3.06065 4.24169 3.12039L3.34419 4.01788C2.44108 4.921 2.06196 6.22292 2.33902 7.4697C3.91099 14.5436 9.43553 20.0681 16.5094 21.6401C17.7562 21.9171 19.0581 21.538 19.9612 20.6349L20.8586 19.7375C20.9184 19.6777 20.9644 19.6317 21.004 19.5886C21.9708 18.5365 21.9708 16.9193 21.004 15.8673C20.9644 15.8241 20.9184 15.7781 20.8586 15.7184L19.3894 14.2491C18.3693 13.229 16.8287 12.9372 15.5063 13.5137C14.7492 13.8437 13.8672 13.6767 13.2832 13.0927L10.8864 10.6959C10.3024 10.1119 10.1354 9.22994 10.4654 8.47283C11.0418 7.15037 10.7501 5.60984 9.72999 4.58974L8.26066 3.12041C8.20093 3.06066 8.15492 3.01464 8.11183 2.97505ZM5.40541 4.07957C5.88363 3.64014 6.6187 3.64014 7.09692 4.07957C7.11343 4.09474 7.13507 4.11613 7.21114 4.1922L8.66933 5.6504C9.25333 6.2344 9.42036 7.11635 9.09034 7.87345C8.51388 9.19592 8.80563 10.7364 9.82573 11.7565L12.2225 14.1534C13.2426 15.1735 14.7832 15.4652 16.1056 14.8887C16.8627 14.5587 17.7447 14.7258 18.3287 15.3098L19.7869 16.768C19.8629 16.844 19.8843 16.8657 19.8995 16.8822C20.3389 17.3604 20.3389 18.0955 19.8995 18.5737C19.8843 18.5902 19.8629 18.6118 19.7869 18.6879L18.9005 19.5742C18.3601 20.1147 17.5809 20.3416 16.8348 20.1758C10.3295 18.7302 5.24893 13.6496 3.8033 7.1443C3.6375 6.39816 3.86438 5.61902 4.40485 5.07855L5.2912 4.1922C5.36726 4.11614 5.3889 4.09474 5.40541 4.07957Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Calling-1": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9901 2.75C13.8509 2.75 14.2319 2.75076 14.5461 2.77889C18.0394 3.09165 20.808 5.86017 21.1207 9.35348C21.1489 9.66768 21.1496 10.0487 21.1496 10.9095V11.8995C21.1496 12.3137 21.4854 12.6495 21.8996 12.6495C22.3138 12.6495 22.6496 12.3137 22.6496 11.8995V10.8591C22.6496 10.0622 22.6496 9.60934 22.6147 9.21971C22.2373 5.00365 18.896 1.66234 14.6799 1.28486C14.2902 1.24998 13.8374 1.24999 13.0404 1.25H12.0001C11.5859 1.25 11.2501 1.58579 11.2501 2C11.2501 2.41422 11.5859 2.75 12.0001 2.75L12.9901 2.75Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.11183 4.0253C6.05974 3.05857 4.44259 3.05857 3.3905 4.0253C3.34742 4.06489 3.30142 4.11089 3.2417 4.17063L2.34419 5.06813C1.44108 5.97125 1.06196 7.27317 1.33902 8.51995C2.91099 15.5938 8.43553 21.1183 15.5094 22.6903C16.7562 22.9674 18.0581 22.5883 18.9612 21.6851L19.8586 20.7877C19.9184 20.728 19.9644 20.6819 20.004 20.6388C20.9708 19.5867 20.9708 17.9696 20.004 16.9175C19.9644 16.8744 19.9184 16.8284 19.8586 16.7686L18.3894 15.2993C17.3693 14.2792 15.8287 13.9875 14.5063 14.564C13.7492 14.894 12.8672 14.7269 12.2832 14.1429L9.88639 11.7461C9.30239 11.1621 9.13536 10.2802 9.46538 9.52308C10.0418 8.20062 9.75009 6.66009 8.72999 5.63999L7.26066 4.17065C7.20092 4.1109 7.15492 4.06489 7.11183 4.0253ZM4.40541 5.12982C4.88363 4.69039 5.6187 4.69039 6.09692 5.12982C6.11343 5.14499 6.13507 5.16638 6.21114 5.24245L7.66933 6.70065C8.25333 7.28465 8.42036 8.1666 8.09034 8.9237C7.51388 10.2462 7.80563 11.7867 8.82573 12.8068L11.2225 15.2036C12.2426 16.2237 13.7832 16.5155 15.1056 15.939C15.8627 15.609 16.7447 15.776 17.3287 16.36L18.7869 17.8182C18.8629 17.8943 18.8843 17.9159 18.8995 17.9324C19.3389 18.4106 19.3389 19.1457 18.8995 19.6239C18.8843 19.6404 18.8629 19.6621 18.7869 19.7381L17.9005 20.6245C17.3601 21.165 16.5809 21.3918 15.8348 21.226C9.32946 19.7804 4.24893 14.6999 2.8033 8.19455C2.6375 7.44841 2.86438 6.66927 3.40485 6.12879L4.2912 5.24245C4.36726 5.16639 4.3889 5.14499 4.40541 5.12982Z"
        fill="#3F3F3F"
      />
      <path
        d="M12.7071 4.78554C12.2929 4.78554 11.9571 5.12132 11.9571 5.53553C11.9571 5.94975 12.2929 6.28553 12.7071 6.28553H13.2728C13.2837 6.28553 13.2938 6.28553 13.3032 6.28554C13.3689 6.28555 13.3994 6.28561 13.4248 6.28597C15.7244 6.3189 17.5806 8.1751 17.6135 10.4747C17.6139 10.5037 17.614 10.5395 17.614 10.6267V11.1924C17.614 11.6066 17.9497 11.9424 18.364 11.9424C18.7782 11.9424 19.114 11.6066 19.114 11.1924V10.6194C19.114 10.6097 19.114 10.6005 19.114 10.5917C19.1139 10.53 19.1139 10.4893 19.1134 10.4532C19.0688 7.342 16.5575 4.83068 13.4463 4.78612C13.405 4.78553 13.3577 4.78553 13.28 4.78554H12.7071Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Calling-2": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.11183 2.97505C7.05974 2.00832 5.44259 2.00832 4.3905 2.97505C4.34741 3.01464 4.30142 3.06065 4.24169 3.12039L3.34419 4.01788C2.44108 4.921 2.06196 6.22292 2.33902 7.4697C3.91099 14.5436 9.43553 20.0681 16.5094 21.6401C17.7562 21.9171 19.0581 21.538 19.9612 20.6349L20.8586 19.7375C20.9184 19.6777 20.9644 19.6317 21.004 19.5886C21.9708 18.5365 21.9708 16.9193 21.004 15.8673C20.9644 15.8241 20.9184 15.7781 20.8586 15.7184L19.3894 14.2491C18.3693 13.229 16.8287 12.9372 15.5063 13.5137C14.7492 13.8437 13.8672 13.6767 13.2832 13.0927L10.8864 10.6959C10.3024 10.1119 10.1354 9.22994 10.4654 8.47283C11.0418 7.15037 10.7501 5.60984 9.72999 4.58974L8.26066 3.12041C8.20093 3.06066 8.15492 3.01464 8.11183 2.97505ZM5.40541 4.07957C5.88363 3.64014 6.6187 3.64014 7.09692 4.07957C7.11343 4.09474 7.13507 4.11613 7.21114 4.1922L8.66933 5.6504C9.25333 6.2344 9.42036 7.11635 9.09034 7.87345C8.51388 9.19592 8.80563 10.7364 9.82573 11.7565L12.2225 14.1534C13.2426 15.1735 14.7832 15.4652 16.1056 14.8887C16.8627 14.5587 17.7447 14.7258 18.3287 15.3098L19.7869 16.768C19.8629 16.844 19.8843 16.8657 19.8995 16.8822C20.3389 17.3604 20.3389 18.0955 19.8995 18.5737C19.8843 18.5902 19.8629 18.6118 19.7869 18.6879L18.9005 19.5742C18.3601 20.1147 17.5809 20.3416 16.8348 20.1758C10.3295 18.7302 5.24893 13.6496 3.8033 7.1443C3.6375 6.39816 3.86438 5.61902 4.40485 5.07855L5.2912 4.1922C5.36726 4.11614 5.3889 4.09474 5.40541 4.07957Z"
        fill="#3F3F3F"
      />
      <path
        d="M14.3032 5.23529C14.3689 5.2353 14.3994 5.23536 14.4248 5.23572C16.7244 5.26865 18.5806 7.12485 18.6135 9.42442C18.6139 9.4535 18.614 9.48921 18.614 9.57645V10.1421C18.614 10.5564 18.9497 10.8921 19.364 10.8921C19.7782 10.8921 20.114 10.5564 20.114 10.1421V9.56919C20.114 9.49148 20.114 9.44419 20.1134 9.40294C20.0688 6.29175 17.5575 3.78043 14.4463 3.73587C14.405 3.73528 14.3578 3.73528 14.28 3.73529H13.7071C13.2929 3.73529 12.9571 4.07107 12.9571 4.48529C12.9571 4.8995 13.2929 5.23529 13.7071 5.23529H14.2728C14.2837 5.23529 14.2938 5.23529 14.3032 5.23529Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Call-Missed": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.11183 2.97505C7.05974 2.00832 5.44259 2.00832 4.3905 2.97505C4.34741 3.01464 4.30142 3.06065 4.24169 3.12039L3.34419 4.01788C2.44108 4.921 2.06196 6.22292 2.33902 7.4697C3.91099 14.5436 9.43553 20.0681 16.5094 21.6401C17.7562 21.9171 19.0581 21.538 19.9612 20.6349L20.8586 19.7375C20.9184 19.6777 20.9644 19.6317 21.004 19.5886C21.9708 18.5365 21.9708 16.9193 21.004 15.8673C20.9644 15.8241 20.9184 15.7781 20.8586 15.7184L19.3894 14.2491C18.3693 13.229 16.8287 12.9372 15.5063 13.5137C14.7492 13.8437 13.8672 13.6767 13.2832 13.0927L10.8864 10.6959C10.3024 10.1119 10.1354 9.22994 10.4654 8.47283C11.0418 7.15037 10.7501 5.60984 9.72999 4.58974L8.26066 3.12041C8.20093 3.06066 8.15492 3.01464 8.11183 2.97505ZM5.40541 4.07957C5.88363 3.64014 6.6187 3.64014 7.09692 4.07957C7.11343 4.09474 7.13507 4.11613 7.21114 4.1922L8.66933 5.6504C9.25333 6.2344 9.42036 7.11635 9.09034 7.87345C8.51388 9.19592 8.80563 10.7364 9.82573 11.7565L12.2225 14.1534C13.2426 15.1735 14.7832 15.4652 16.1056 14.8887C16.8627 14.5587 17.7447 14.7258 18.3287 15.3098L19.7869 16.768C19.8629 16.844 19.8843 16.8657 19.8995 16.8822C20.3389 17.3604 20.3389 18.0955 19.8995 18.5737C19.8843 18.5902 19.8629 18.6118 19.7869 18.6879L18.9005 19.5742C18.3601 20.1147 17.5809 20.3416 16.8348 20.1758C10.3295 18.7302 5.24893 13.6496 3.8033 7.1443C3.6375 6.39816 3.86438 5.61902 4.40485 5.07855L5.2912 4.1922C5.36726 4.11614 5.3889 4.09474 5.40541 4.07957Z"
        fill="#3F3F3F"
      />
      <path
        d="M20.8945 3.65165C21.1874 3.35876 21.1874 2.88389 20.8945 2.59099C20.6016 2.2981 20.1267 2.2981 19.8339 2.59099L18.2429 4.18198L16.6519 2.59099C16.359 2.2981 15.8841 2.2981 15.5912 2.59099C15.2983 2.88389 15.2983 3.35876 15.5912 3.65165L17.1822 5.24264L15.5912 6.83363C15.2983 7.12653 15.2983 7.6014 15.5912 7.89429C15.8841 8.18719 16.359 8.18719 16.6519 7.89429L18.2429 6.3033L19.8339 7.89429C20.1267 8.18719 20.6016 8.18719 20.8945 7.89429C21.1874 7.6014 21.1874 7.12653 20.8945 6.83363L19.3035 5.24264L20.8945 3.65165Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Call-Silent": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8413 4.28033C20.1342 3.98743 20.1342 3.51256 19.8413 3.21967C19.5484 2.92678 19.0735 2.92678 18.7806 3.21967L7.45479 14.5456L6.39233 15.608L2.7807 19.2197C2.48781 19.5126 2.48781 19.9874 2.78071 20.2803C3.0736 20.5732 3.54847 20.5732 3.84137 20.2803L19.8413 4.28033Z"
        fill="#3F3F3F"
      />
      <path
        d="M5.15541 3.82957C5.63363 3.39014 6.3687 3.39014 6.84692 3.82957C6.86343 3.84474 6.88507 3.86613 6.96114 3.9422L8.41933 5.4004C9.00333 5.9844 9.17036 6.86635 8.84034 7.62345C8.26388 8.94592 8.55563 10.4864 9.57573 11.5065L10.0401 11.9709L10.3361 11.6642C10.5 11.5 10.3342 11.6661 10.6271 11.3732C10.92 11.0803 10.6877 11.3127 11 11L11.1007 10.9102L10.6364 10.4459C10.0524 9.86188 9.88536 8.97994 10.2154 8.22283C10.7918 6.90037 10.5001 5.35984 9.47999 4.33974L8.01066 2.87041C7.95093 2.81066 7.90492 2.76464 7.86183 2.72505C6.80974 1.75832 5.19259 1.75832 4.1405 2.72505C4.09741 2.76464 4.05142 2.81065 3.99169 2.87039L3.09419 3.76788C2.19108 4.671 1.81196 5.97292 2.08902 7.2197C2.79629 10.4024 4.30369 13.2715 6.39233 15.608C6.5 15.5 6.43377 15.5666 6.5 15.5C6.5 15.5 6.71143 15.2886 7 15C7.29709 14.7029 7.24432 14.756 7.24432 14.756C7.24432 14.756 7.299 14.7013 7.45479 14.5456C5.56305 12.4083 4.1974 9.79272 3.5533 6.8943C3.3875 6.14816 3.61438 5.36902 4.15485 4.82855L5.0412 3.9422C5.11726 3.86614 5.1389 3.84474 5.15541 3.82957Z"
        fill="#3F3F3F"
      />
      <path
        d="M10.0998 16.9691C9.79087 16.7404 9.35913 16.7625 9.08735 17.0343C8.77211 17.3495 8.7974 17.8692 9.15481 18.1357C11.2286 19.6819 13.6376 20.8074 16.2594 21.3901C17.5062 21.6671 18.8081 21.288 19.7112 20.3849L20.6086 19.4875C20.6684 19.4277 20.7144 19.3817 20.754 19.3386C21.7208 18.2865 21.7208 16.6693 20.754 15.6173C20.7144 15.5741 20.6684 15.5281 20.6086 15.4684L19.1394 13.9991C18.1193 12.979 16.5787 12.6872 15.2563 13.2637C14.7733 13.4742 14.2396 13.4825 13.7648 13.3093C13.3925 13.1735 12.9462 13.1754 12.6659 13.4557C12.3571 13.7645 12.3677 14.2775 12.7494 14.4896C13.6893 15.0117 14.8371 15.0827 15.8556 14.6387C16.6127 14.3087 17.4947 14.4758 18.0787 15.0598L19.5369 16.518C19.6129 16.594 19.6343 16.6157 19.6495 16.6322C20.0889 17.1104 20.0889 17.8455 19.6495 18.3237C19.6343 18.3402 19.6129 18.3618 19.5369 18.4379L18.6505 19.3242C18.1101 19.8647 17.3309 20.0916 16.5848 19.9258C14.1942 19.3945 11.9959 18.3724 10.0998 16.9691Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
};

export type CallIconsType = keyof typeof CallIcons;
