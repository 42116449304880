export const MenuIcons = {
  "Menu-Hamburger": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5.25C4.58579 5.25 4.25 5.58579 4.25 6C4.25 6.41421 4.58579 6.75 5 6.75H19C19.4142 6.75 19.75 6.41421 19.75 6C19.75 5.58579 19.4142 5.25 19 5.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 11.25C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H19C19.4142 12.75 19.75 12.4142 19.75 12C19.75 11.5858 19.4142 11.25 19 11.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 17.25C4.58579 17.25 4.25 17.5858 4.25 18C4.25 18.4142 4.58579 18.75 5 18.75H19C19.4142 18.75 19.75 18.4142 19.75 18C19.75 17.5858 19.4142 17.25 19 17.25H5Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Menu-Fries": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5.25C4.58579 5.25 4.25 5.58579 4.25 6C4.25 6.41421 4.58579 6.75 5 6.75H19C19.4142 6.75 19.75 6.41421 19.75 6C19.75 5.58579 19.4142 5.25 19 5.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 11.25C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H11C11.4142 12.75 11.75 12.4142 11.75 12C11.75 11.5858 11.4142 11.25 11 11.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 17.25C4.58579 17.25 4.25 17.5858 4.25 18C4.25 18.4142 4.58579 18.75 5 18.75H19C19.4142 18.75 19.75 18.4142 19.75 18C19.75 17.5858 19.4142 17.25 19 17.25H5Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Menu-Hamburger-1": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5.25C4.58579 5.25 4.25 5.58579 4.25 6C4.25 6.41421 4.58579 6.75 5 6.75H19C19.4142 6.75 19.75 6.41421 19.75 6C19.75 5.58579 19.4142 5.25 19 5.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 11.25C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H11C11.4142 12.75 11.75 12.4142 11.75 12C11.75 11.5858 11.4142 11.25 11 11.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 17.25C4.58579 17.25 4.25 17.5858 4.25 18C4.25 18.4142 4.58579 18.75 5 18.75H8C8.41421 18.75 8.75 18.4142 8.75 18C8.75 17.5858 8.41421 17.25 8 17.25H5Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Menu-Veggie-Burger": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 8.25C4.58579 8.25 4.25 8.58579 4.25 9C4.25 9.41421 4.58579 9.75 5 9.75H19C19.4142 9.75 19.75 9.41421 19.75 9C19.75 8.58579 19.4142 8.25 19 8.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 14.25C4.58579 14.25 4.25 14.5858 4.25 15C4.25 15.4142 4.58579 15.75 5 15.75H19C19.4142 15.75 19.75 15.4142 19.75 15C19.75 14.5858 19.4142 14.25 19 14.25H5Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Menu-Hotdog": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5.25C8.58579 5.25 8.25 5.58579 8.25 6C8.25 6.41421 8.58579 6.75 9 6.75H15C15.4142 6.75 15.75 6.41421 15.75 6C15.75 5.58579 15.4142 5.25 15 5.25H9Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 11.25C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H19C19.4142 12.75 19.75 12.4142 19.75 12C19.75 11.5858 19.4142 11.25 19 11.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M9 17.25C8.58579 17.25 8.25 17.5858 8.25 18C8.25 18.4142 8.58579 18.75 9 18.75H15C15.4142 18.75 15.75 18.4142 15.75 18C15.75 17.5858 15.4142 17.25 15 17.25H9Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Menu-Strawberry": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5.25C4.58579 5.25 4.25 5.58579 4.25 6C4.25 6.41421 4.58579 6.75 5 6.75H19C19.4142 6.75 19.75 6.41421 19.75 6C19.75 5.58579 19.4142 5.25 19 5.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M8 11.25C7.58579 11.25 7.25 11.5858 7.25 12C7.25 12.4142 7.58579 12.75 8 12.75H16C16.4142 12.75 16.75 12.4142 16.75 12C16.75 11.5858 16.4142 11.25 16 11.25H8Z"
        fill="#3F3F3F"
      />
      <path
        d="M10 17.25C9.58579 17.25 9.25 17.5858 9.25 18C9.25 18.4142 9.58579 18.75 10 18.75H14C14.4142 18.75 14.75 18.4142 14.75 18C14.75 17.5858 14.4142 17.25 14 17.25H10Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Menu-Cheesesburger": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 5.25C6.58579 5.25 6.25 5.58579 6.25 6C6.25 6.41421 6.58579 6.75 7 6.75H21C21.4142 6.75 21.75 6.41421 21.75 6C21.75 5.58579 21.4142 5.25 21 5.25H7Z"
        fill="#3F3F3F"
      />
      <path
        d="M7 11.25C6.58579 11.25 6.25 11.5858 6.25 12C6.25 12.4142 6.58579 12.75 7 12.75H21C21.4142 12.75 21.75 12.4142 21.75 12C21.75 11.5858 21.4142 11.25 21 11.25H7Z"
        fill="#3F3F3F"
      />
      <path
        d="M7 17.25C6.58579 17.25 6.25 17.5858 6.25 18C6.25 18.4142 6.58579 18.75 7 18.75H21C21.4142 18.75 21.75 18.4142 21.75 18C21.75 17.5858 21.4142 17.25 21 17.25H7Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 12C5 12.5523 4.55228 13 4 13C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11C4.55228 11 5 11.4477 5 12Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 6C5 6.55228 4.55228 7 4 7C3.44772 7 3 6.55228 3 6C3 5.44772 3.44772 5 4 5C4.55228 5 5 5.44772 5 6Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 18C5 18.5523 4.55228 19 4 19C3.44772 19 3 18.5523 3 18C3 17.4477 3.44772 17 4 17C4.55228 17 5 17.4477 5 18Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  Menu: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 5.25C12.5858 5.25 12.25 5.58579 12.25 6C12.25 6.41421 12.5858 6.75 13 6.75L19 6.75C19.4142 6.75 19.75 6.41421 19.75 6C19.75 5.58579 19.4142 5.25 19 5.25H13Z"
        fill="#3F3F3F"
      />
      <path
        d="M5 11.25C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H19C19.4142 12.75 19.75 12.4142 19.75 12C19.75 11.5858 19.4142 11.25 19 11.25H5Z"
        fill="#3F3F3F"
      />
      <path
        d="M9 17.25C8.58579 17.25 8.25 17.5858 8.25 18C8.25 18.4142 8.58579 18.75 9 18.75L19 18.75C19.4142 18.75 19.75 18.4142 19.75 18C19.75 17.5858 19.4142 17.25 19 17.25L9 17.25Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Menu-Meatballs": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 9.25C2.48122 9.25 1.25 10.4812 1.25 12C1.25 13.5188 2.48122 14.75 4 14.75C5.51878 14.75 6.75 13.5188 6.75 12C6.75 10.4812 5.51878 9.25 4 9.25ZM2.75 12C2.75 11.3096 3.30964 10.75 4 10.75C4.69036 10.75 5.25 11.3096 5.25 12C5.25 12.6904 4.69036 13.25 4 13.25C3.30964 13.25 2.75 12.6904 2.75 12Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.25C10.4812 9.25 9.25 10.4812 9.25 12C9.25 13.5188 10.4812 14.75 12 14.75C13.5188 14.75 14.75 13.5188 14.75 12C14.75 10.4812 13.5188 9.25 12 9.25ZM10.75 12C10.75 11.3096 11.3096 10.75 12 10.75C12.6904 10.75 13.25 11.3096 13.25 12C13.25 12.6904 12.6904 13.25 12 13.25C11.3096 13.25 10.75 12.6904 10.75 12Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 9.25C18.4812 9.25 17.25 10.4812 17.25 12C17.25 13.5188 18.4812 14.75 20 14.75C21.5188 14.75 22.75 13.5188 22.75 12C22.75 10.4812 21.5188 9.25 20 9.25ZM18.75 12C18.75 11.3096 19.3096 10.75 20 10.75C20.6904 10.75 21.25 11.3096 21.25 12C21.25 12.6904 20.6904 13.25 20 13.25C19.3096 13.25 18.75 12.6904 18.75 12Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Menu-Kebab": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25C10.4812 1.25 9.25 2.48122 9.25 4C9.25 5.51878 10.4812 6.75 12 6.75C13.5188 6.75 14.75 5.51878 14.75 4C14.75 2.48122 13.5188 1.25 12 1.25ZM10.75 4C10.75 3.30964 11.3096 2.75 12 2.75C12.6904 2.75 13.25 3.30964 13.25 4C13.25 4.69036 12.6904 5.25 12 5.25C11.3096 5.25 10.75 4.69036 10.75 4Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.25C10.4812 9.25 9.25 10.4812 9.25 12C9.25 13.5188 10.4812 14.75 12 14.75C13.5188 14.75 14.75 13.5188 14.75 12C14.75 10.4812 13.5188 9.25 12 9.25ZM10.75 12C10.75 11.3096 11.3096 10.75 12 10.75C12.6904 10.75 13.25 11.3096 13.25 12C13.25 12.6904 12.6904 13.25 12 13.25C11.3096 13.25 10.75 12.6904 10.75 12Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17.25C10.4812 17.25 9.25 18.4812 9.25 20C9.25 21.5188 10.4812 22.75 12 22.75C13.5188 22.75 14.75 21.5188 14.75 20C14.75 18.4812 13.5188 17.25 12 17.25ZM10.75 20C10.75 19.3096 11.3096 18.75 12 18.75C12.6904 18.75 13.25 19.3096 13.25 20C13.25 20.6904 12.6904 21.25 12 21.25C11.3096 21.25 10.75 20.6904 10.75 20Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
  "Menu-Candy-Box": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 1.25C2.48122 1.25 1.25 2.48122 1.25 4C1.25 5.51878 2.48122 6.75 4 6.75C5.51878 6.75 6.75 5.51878 6.75 4C6.75 2.48122 5.51878 1.25 4 1.25ZM2.75 4C2.75 3.30964 3.30964 2.75 4 2.75C4.69036 2.75 5.25 3.30964 5.25 4C5.25 4.69036 4.69036 5.25 4 5.25C3.30964 5.25 2.75 4.69036 2.75 4Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25C10.4812 1.25 9.25001 2.48122 9.25001 4C9.25001 5.51878 10.4812 6.75 12 6.75C13.5188 6.75 14.75 5.51878 14.75 4C14.75 2.48122 13.5188 1.25 12 1.25ZM10.75 4C10.75 3.30964 11.3096 2.75 12 2.75C12.6904 2.75 13.25 3.30964 13.25 4C13.25 4.69036 12.6904 5.25 12 5.25C11.3096 5.25 10.75 4.69036 10.75 4Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 1.25C18.4812 1.25 17.25 2.48122 17.25 4C17.25 5.51878 18.4812 6.75 20 6.75C21.5188 6.75 22.75 5.51878 22.75 4C22.75 2.48122 21.5188 1.25 20 1.25ZM18.75 4C18.75 3.30964 19.3097 2.75 20 2.75C20.6904 2.75 21.25 3.30964 21.25 4C21.25 4.69036 20.6904 5.25 20 5.25C19.3097 5.25 18.75 4.69036 18.75 4Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 9.25C2.48122 9.25 1.25 10.4812 1.25 12C1.25 13.5188 2.48122 14.75 4 14.75C5.51878 14.75 6.75 13.5188 6.75 12C6.75 10.4812 5.51878 9.25 4 9.25ZM2.75 12C2.75 11.3096 3.30964 10.75 4 10.75C4.69036 10.75 5.25 11.3096 5.25 12C5.25 12.6904 4.69036 13.25 4 13.25C3.30964 13.25 2.75 12.6904 2.75 12Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.25C10.4812 9.25 9.25 10.4812 9.25 12C9.25 13.5188 10.4812 14.75 12 14.75C13.5188 14.75 14.75 13.5188 14.75 12C14.75 10.4812 13.5188 9.25 12 9.25ZM10.75 12C10.75 11.3096 11.3096 10.75 12 10.75C12.6904 10.75 13.25 11.3096 13.25 12C13.25 12.6904 12.6904 13.25 12 13.25C11.3096 13.25 10.75 12.6904 10.75 12Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 9.25C18.4812 9.25 17.25 10.4812 17.25 12C17.25 13.5188 18.4812 14.75 20 14.75C21.5188 14.75 22.75 13.5188 22.75 12C22.75 10.4812 21.5188 9.25 20 9.25ZM18.75 12C18.75 11.3096 19.3097 10.75 20 10.75C20.6904 10.75 21.25 11.3096 21.25 12C21.25 12.6904 20.6904 13.25 20 13.25C19.3097 13.25 18.75 12.6904 18.75 12Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 17.25C2.48122 17.25 1.25 18.4812 1.25 20C1.25 21.5188 2.48122 22.75 4 22.75C5.51878 22.75 6.75 21.5188 6.75 20C6.75 18.4812 5.51878 17.25 4 17.25ZM2.75 20C2.75 19.3096 3.30964 18.75 4 18.75C4.69036 18.75 5.25 19.3096 5.25 20C5.25 20.6904 4.69036 21.25 4 21.25C3.30964 21.25 2.75 20.6904 2.75 20Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17.25C10.4812 17.25 9.25001 18.4812 9.25001 20C9.25001 21.5188 10.4812 22.75 12 22.75C13.5188 22.75 14.75 21.5188 14.75 20C14.75 18.4812 13.5188 17.25 12 17.25ZM10.75 20C10.75 19.3096 11.3097 18.75 12 18.75C12.6904 18.75 13.25 19.3096 13.25 20C13.25 20.6904 12.6904 21.25 12 21.25C11.3096 21.25 10.75 20.6904 10.75 20Z"
        fill="#3F3F3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 17.25C18.4812 17.25 17.25 18.4812 17.25 20C17.25 21.5188 18.4812 22.75 20 22.75C21.5188 22.75 22.75 21.5188 22.75 20C22.75 18.4812 21.5188 17.25 20 17.25ZM18.75 20C18.75 19.3096 19.3097 18.75 20 18.75C20.6904 18.75 21.25 19.3096 21.25 20C21.25 20.6904 20.6904 21.25 20 21.25C19.3097 21.25 18.75 20.6904 18.75 20Z"
        fill="#3F3F3F"
      />
    </svg>
  ),
};

export type MenuIconsType = keyof typeof MenuIcons;
